const generateKeywords = async () => {
  const categoryNumber = localStorage.getItem("categoryNumber");
  const allData = JSON.parse(localStorage.getItem("allData"));

  const content = `Below is a list of frequently asked questions (FAQs). 
  Your task is to extract an adequate number of keyword(s) that can help users and potential users navigate through the FAQs. Only consider the questions that have answers. Which keyword(s) do you think will be the most important and most critical for our users? Let’s first aim for the audience who don't know the service and product much, but are interested in buying one, so we can build sophisticated FAQS. Check out the analytics and see which keywords and categories people are most interacting with. Give those keywords priorities. We will call these keywords “category” since we want this to work like a category and every FAQ must belong in one. Each category / keywords must have at least one associated FAQ. Every FAQ must be associated with at least one category. No category should be created that does not have any FAQ associated with. 
  The output should be in JSON format, where each entry includes the FAQ's ID and a list of keyword(s) it belongs to, maintaining the format of a single keywords array per FAQ. This means each category array should contain only one category, reflecting the primary theme of the FAQ. 
  Ensure no more than ${categoryNumber} distinct categories are used across all FAQs. You can have multiple ids assocaited with single category. 
  The output format should look like this example: 
  [
    { "category": "greeting", "ids": [123456, 987654] },
    { "category": "customer support", "ids": [93847333, 246810] },
    { "category": "product information", "ids": [93199140] }
  ]
  
  Please do not include any titles or additional text in the output.
  FAQs: ${JSON.stringify(allData.faqs)}
  FAQ Clicks: ${JSON.stringify(allData.faqClicks)}
  Keyword Clicks: ${JSON.stringify(allData.categoryClickData)}
  Visitor Data: ${JSON.stringify(allData.visitorData)}
  `;

  const requestBody = JSON.stringify({
    model: "gpt-3.5-turbo",
    messages: [{ role: "user", content: content }],
    max_tokens: 4000,
  });

  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    },
    body: requestBody,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  const botResponse = {
    role: "assistant",
    content: data.choices[0].message.content,
  };

  const extractCategories = (botResponse) => {
    const faqArray = JSON.parse(botResponse.content);
    const allCategories = faqArray.flatMap((item) => item.category);
    const uniqueCategories = Array.from(new Set(allCategories));
    const flatCategoryString = uniqueCategories.join(", ");

    return {
      faqArray,
      flatCategoryString,
      categoryToIdsMap: faqArray.reduce((acc, item) => {
        const cat = item.category;
        if (!acc[cat]) {
          acc[cat] = [];
        }
        acc[cat].push(...item.ids);
        return acc;
      }, {}),
    };
  };

  return extractCategories(botResponse);
};

export default generateKeywords;
