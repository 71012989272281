import React, { useState, useEffect } from "react";
import "quill/dist/quill.snow.css";
import "./styles.css";
import TextEditor from "./TextEditor";

const ContentCardModal = ({
  onSave,
  onClose,
  existingData = { id: null, title: "", description: "" },
  modalType = "edit", // Can be 'edit' or 'delete' for different modal types
}) => {
  const [id, setId] = useState(existingData.id || "");
  const [title, setTitle] = useState(existingData.title || "");
  const [url, setUrl] = useState(""); // This is the missing state definition
  const [description, setDescription] = useState(
    existingData.description || ""
  );

  useEffect(() => {
    // Update local state when existingData changes, typical when editing a card
    setId(existingData.id || "");
    setTitle(existingData.title || "");
    setDescription(existingData.description || "");
  }, [existingData]);

  const handleScrape = async () => {
    try {
      let formattedUrl = url;
      if (!/^https?:\/\//i.test(url)) {
        // Prepend http:// if the URL does not start with http:// or https://
        formattedUrl = `http://${url}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/scrape`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: formattedUrl }), // Send the formatted URL to the server
      });

      const jsonData = await response.json(); // Get the JSON data from the response

      if (response.ok && Array.isArray(jsonData)) {
        console.log("We've scraped", jsonData);
        setDescription(jsonData.join("\n")); // Join array elements with newline
      } else {
        console.error("Unexpected response format:", jsonData);
        setDescription(
          "Failed to scrape content. Please check the URL or try again later."
        );
      }
    } catch (error) {
      console.error("Scraping failed:", error);
      setDescription("Scraping failed due to an error.");
    }
  };

  const handleSave = async () => {
    // Added async here
    const cardSummaryPrompt =
      "Please condense the following detailed information into a very brief, bullet-point style summary. Use minimal words to capture the essence of each point, simplify descriptions, and use shorthand where applicable (e.g., replace full sentences with phrases or single words where the meaning remains clear). Assume familiarity with basic concepts to avoid redundant explanations. Aim to reduce the text as much as possible while retaining all critical information. Remove all the programming code and tags";

    const updatedMessages = `${cardSummaryPrompt} ${description}`;

    const requestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [
        { role: "system", content: cardSummaryPrompt },
        { role: "user", content: description },
      ],
      max_tokens: 4000,
    });
    console.log("What we are sending chatGPT:", updatedMessages);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`, // Ensure this is correct
          },
          body: requestBody,
        }
      );

      const data = await response.json(); // Assuming you need to process the response

      // Include the id in the onSave call if it exists, indicating an update
      onSave({
        id, // Include id if it's available
        title,
        description: data.choices[0].message.content, // Updated to use response
      });

      onClose(); // Close the modal after saving
    } catch (error) {
      console.error("Failed to save FAQ:", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-md">
        <div className="modal-content-md">
          {modalType === "edit" && (
            <>
              <h2>{id ? "Edit Knowledge" : "Add Knowledge"}</h2>
              <p>
                Provide as much knowledge as possible to train and enhance your
                AI support engine for your audience.{" "}
              </p>
              <label className="signinLabel">Knowledge Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title here..."
                className="input-md"
              />
              <label className="signinLabel">
                Enter Webpage URL *optional*
              </label>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter URL to scrape..."
                  className="input-md"
                />
                <button className="button-md scrape" onClick={handleScrape}>
                  Scrape URL
                </button>
              </span>
              <label className="signinLabel">Detail</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter data here..."
                className="input-md"
              />

              <div className="ModalButtonGroup">
                <button className="button-md" onClick={onClose}>
                  Cancel
                </button>
                <button className="button-md save" onClick={handleSave}>
                  <i data-feather="youtube"></i> Save
                </button>
              </div>
            </>
          )}
          {modalType === "delete" && (
            <>
              <h2>Confirm Delete</h2>
              <p>Are you sure you want to delete this content card?</p>
              <div className="DeleteModal">
                <button className="button-md" onClick={handleSave}>
                  Yes
                </button>
                <button className="button-md" onClick={onClose}>
                  No
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentCardModal;
