import React, { useState, useEffect } from "react";
import "quill/dist/quill.snow.css";
import "./styles.css";
import TextEditor from "./TextEditor";
import CreatableSelect from "react-select/creatable";

const FAQModal = ({
  onSave,
  onClose,
  existingData = {},
  modalType = "text",
  confirmMessage,
  allTags,
  onTagUpdate, // Add onTagUpdate prop
}) => {
  const [id, setId] = useState(existingData.id || "");
  const [question, setQuestion] = useState(existingData.question || "");
  const [answer, setAnswer] = useState(existingData.answer || "");
  const [tags, setTags] = useState(
    existingData.tags ? existingData.tags.join(", ") : ""
  );
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  useEffect(() => {
    if (modalType === "text") {
      setQuestion(existingData.question || "");
      setAnswer(existingData.answer || "");
      setTags(existingData.tags ? existingData.tags.join(", ") : "");
    }
  }, [existingData, modalType]);

  const handleTagsChange = (selectedOptions) => {
    const newTags = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setTags(newTags.join(", "));

    // Update the parent component with the new tags
    if (typeof onTagUpdate === "function") {
      onTagUpdate(newTags.map((tag) => [tag, 1]));
    }
  };

  const generateAnswer = async () => {
    setIsLoading(true); // Start loading

    const promptIntro =
      answer.trim() === ""
        ? `Provide the best answer for this question based on the company data we have.`
        : `Refine the existing answer using the detailed company data provided. Face check the exsiting answer and see if they are still hold truth.`;

    const answerGenerator = `${promptIntro} 
  IMPORTANT: YOU ARE RETURNING HTML. Write from the perspective of the company representative. Engage the audience directly. Use any previously provided answers where applicable. Aim to enhance customer conversion. DO NOT USE 3RD PERSON PERSPECTIVE TERMS such as 'Based on the detailed company data provided', 'Here is a refined response', 'According to the data provided', 'Based on the data company provided'. Always refer to yourself as 'We', never 'they'.
  Ensure your response increases potential customer conversion. If a complete answer cannot be derived from the provided data, clearly indicate the need for further information and emphasize its importance in addressing customer queries effectively. Be concise and direct.
  After answering the question, thoroughly explain the details. Review the company data again to ensure no important details, such as pricing models, features, or services, are missed. Include CTAs or links to increase the CTR of this FAQ. The KPI will be CTR (buy, sign up, contact, etc.). Use appropriate HTML tags like mailto, a href, etc., for CTAs.
  Provide examples for testimonials, pricing options, event lists, and rules. Show category or keyword details as examples in bullet points. Use simple HTML for the best presentation, utilizing typography, bullet points, and spacing. Avoid using asterisks (*) for structure.
  Create the answer with nice typography using HTML tags. The document should have section titles and content: h3 for section titles, <h3> for subsection titles, <p> paragraphs. The main title of the answer is not needed since the question serves as the main title. Use <OL> <LI> for bullet points where appropriate.`;

    // Retrieve and parse data from localStorage
    const contentCards = JSON.parse(
      localStorage.getItem("contentCards") || "[]"
    );
    const faqs = JSON.parse(localStorage.getItem("faqs") || "[]");
    const faqsWithAnswers = faqs.filter((faq) => faq.answer !== null);

    // Format the contentCards and FAQs for inclusion in the requestBody
    const companyDataMessage = {
      role: "system",
      content: `Company data: ${contentCards
        .map((card) => card.description)
        .join(". ")}`,
    };

    const faqsMessage = {
      role: "system",
      content: `FAQs: ${faqsWithAnswers
        .map((faq) => `${faq.question} - ${faq.answer}`)
        .join(". ")}`,
    };

    const requestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [
        { role: "user", content: question },
        companyDataMessage, // Company data message
        faqsMessage, // FAQs message
        { role: "system", content: answerGenerator },
        {
          role: "user",
          content:
            answer.trim() !== ""
              ? `Current answer: ${answer}`
              : "No current answer provided.",
        }, // Adding the current answer text
      ],
      max_tokens: 4000,
    });

    console.log("Request Body:", requestBody);

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: requestBody,
        }
      );

      const data = await response.json();
      console.log("Returned Answer", data);
      if (data.choices && data.choices.length > 0) {
        const fullResponse = data.choices[0].message.content;
        const answerMatch = fullResponse.match(/^(.*?)(\*\*Tags:\*\* .*)$/s);
        const newAnswer = answerMatch
          ? answerMatch[1].trim()
          : fullResponse.trim();
        const tagsMatch =
          answerMatch && answerMatch[2].match(/\*\*Tags:\*\* (.*)$/);
        const newTags = tagsMatch
          ? tagsMatch[1].split(",").map((tag) => tag.trim())
          : [];

        // Combine new tags with existing tags
        const existingTags = tags.split(", ").map((tag) => tag.trim());
        const updatedTags = [...new Set([...existingTags, ...newTags])];

        setAnswer(newAnswer); // Update state with the response
        setTags(updatedTags.join(", ")); // Update state with combined tags

        // Update the parent component with the new tags
        if (typeof onTagUpdate === "function") {
          onTagUpdate(updatedTags.map((tag) => [tag, 1]));
        }
      } else {
        console.log("No choices available in the response");
      }
    } catch (error) {
      console.error("Failed to generate answer:", error);
    } finally {
      setIsLoading(false); // End loading regardless of the outcome
    }
  };

  const handleSave = () => {
    const tagsArray = tags
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag);
    onSave({ id, question, answer, tags: tagsArray });
    onClose(); // Close modal after save
  };

  return (
    <div className="modal-overlay">
      <div className="modal-md">
        <div className="modal-content-md">
          {modalType === "text" && (
            <>
              <h2>{id ? "Edit FAQ" : "Add FAQ"}</h2>
              <p>
                At anytime, you can press enhance answer, and our Ai will help
                you complete this FAQ.{" "}
              </p>
              <label className="signinLabel">Question</label>
              <input
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Enter question here..."
                className="input-md"
              />
              <label className="signinLabel">Answer</label>
              <TextEditor
                value={answer}
                onChange={setAnswer}
                placeholder="Enter answer here..."
              />
              <label className="signinLabel">Keywords</label>
              <CreatableSelect
                isMulti
                value={tags.split(", ").map((tag) => ({
                  label: tag,
                  value: tag,
                }))}
                onChange={handleTagsChange}
                options={allTags.map(([tag]) => ({
                  label: tag,
                  value: tag,
                }))}
                placeholder="Enter tags, separated by commas"
                className="input-md"
              />
              <div className="ModalButtonGroup">
                <button
                  className="button-md2"
                  onClick={generateAnswer}
                  disabled={isLoading}
                >
                  {isLoading ? "Generating..." : "Enhance Answer"}
                </button>
                <button className="button-md" onClick={onClose}>
                  Cancel
                </button>
                <button className="button-md save" onClick={handleSave}>
                  Save
                </button>
              </div>
            </>
          )}
          {modalType === "confirm" && (
            <>
              <h2>Confirmation</h2>
              <p>{confirmMessage}</p>
              <div className="ModalButtonGroup">
                <button className="button-md" onClick={handleSave}>
                  Yes
                </button>
                <button className="button-md" onClick={onClose}>
                  No
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQModal;
