// ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const { publicId } = useParams();
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await axios.get("/api/meta-data", {
          params: { publicId },
        });
        const { darkMode } = response.data;
        const initialTheme = darkMode ? "dark" : "light";
        setTheme(initialTheme);
        localStorage.setItem("theme", initialTheme);
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    };

    fetchMetaData();
  }, [publicId]);

  useEffect(() => {
    if (theme === "dark") {
      import("./dark.css");
    } else {
      import("./light.css");
    }
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
