import React from "react";
import FAQList from "./FAQList";

const FAQPage = ({
  pageOwner,
  isSignedIn,
  faqData,
  viewMode,
  categoryNumber,
  isEmbed,
  setHasFaqs, // Accept setHasFaqs prop
}) => {
  console.log("FAQ Page:", isSignedIn);
  // Accept viewMode prop
  return (
    <div className="faq-page">
      <FAQList
        faqData={faqData} // Pass faqData to FAQList
        isSignedIn={isSignedIn}
        viewMode={viewMode} // Pass viewMode to FAQList
        categoryNumber={categoryNumber}
        isEmbed={isEmbed}
        setHasFaqs={setHasFaqs} // Pass setHasFaqs to FAQList
      />
    </div>
  );
};

export default FAQPage;
