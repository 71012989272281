import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FAQContainer from "./FAQContainer";
import "./DemoPage.css";
import "./Embed.css";
import Analytics from "./Analytics";
import { useTheme } from "./ThemeContext"; // Make sure to adjust the path as necessary

const MiniFaq = ({ faqData, viewMode }) => {
  const { theme } = useTheme(); // Add this line to use the theme context
  const { publicId } = useParams(); // Get publicId directly from URL params
  localStorage.setItem("publicId", publicId); //we need to transport this variable for the category build
  const [uniqueVisitors, setUniqueVisitors] = useState(0);
  const [totalVisits, setTotalVisitors] = useState(0);
  const [isEmbed, setIsEmbed] = useState(true);

  const [faqs, setFaqs] = useState([]); // State to hold FAQ data
  const [pageName, setPageName] = useState("Help"); // Default value
  const [categoryNumber, setCategoryNumber] = useState([]); // Default value

  const [faqClickData, setFaqClickData] = useState(null);

  // Fetch data from database when component mounts
  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const response = await axios.get(`/api/unique-visitors/${publicId}`); // Make sure this URL matches your actual API endpoint

        // Update the state with fetched data
        setUniqueVisitors(response.data.uniqueVisitors);
        setTotalVisitors(response.data.totalVisits);
        // Store visitor data in local storage
        localStorage.setItem(
          "visitorData",
          JSON.stringify({
            uniqueVisitors: response.data.uniqueVisitors,
            totalVisits: response.data.totalVisits,
          })
        );
      } catch (error) {
        console.error("Error fetching analytics data:", error);
        // ... (error handling)
      }
    };

    fetchAnalyticsData();
  }, []);

  useEffect(() => {
    const fetchFaqClickData = async () => {
      try {
        const response = await axios.get(`/api/all-faqs-clicks/${publicId}`);
        setFaqClickData(response.data);
        // Store the fetched data in local storage for access by FaqCleanup.js
        localStorage.setItem("faqClickData", JSON.stringify(response.data));
      } catch (error) {
        console.error("Error fetching FAQ CLICK data:", error);
      }
    };

    fetchFaqClickData();
  }, []);

  // New useEffect for fetching category click data
  const [categoryClickData, setCategoryClickData] = useState([]);
  useEffect(() => {
    const fetchCategoryClickData = async () => {
      try {
        console.log("Public Id inside Analytics.js:", publicId);

        const response = await axios.get(`/api/category-clicks/${publicId}`);

        // Transforming response.data.categoryClicks to an array if it's an object
        const data = response.data.categoryClicks;
        let transformedData = [];
        if (data && typeof data === "object" && !Array.isArray(data)) {
          transformedData = Object.keys(data).map((key) => ({
            name: key,
            clicks: data[key],
          }));
        } else {
          transformedData = Array.isArray(data) ? data : [];
        }

        setCategoryClickData(transformedData);
        localStorage.setItem(
          "categoryClickData",
          JSON.stringify(transformedData)
        );
      } catch (error) {
        console.error("Failed to fetch category click data:", error);
      }
    };

    fetchCategoryClickData();
  }, []);

  // Define a placeholder function for onChatGPTData when viewMode is viewerPage
  const onChatGPTData = () => {
    console.warn(
      "Warning: onChatGPTData function not passed down in viewerPage"
    );
  };

  //all the analytics logging
  function generateUniqueVisitorId() {
    return "visitor-" + Math.random().toString(36).substr(2, 9);
  }

  useEffect(() => {
    let visitorId = localStorage.getItem("visitorId");
    if (!visitorId) {
      visitorId = generateUniqueVisitorId(); // You need to define this function to generate a unique ID
      localStorage.setItem("visitorId", visitorId);
    }

    axios
      .post("/api/log-visit", { publicId, visitorId })
      .catch((error) => console.error("Error logging visit", error));
  }, []);

  return (
    <div className={`iframeHolder ${theme}`}>
      <FAQContainer
        isEmbed={isEmbed}
        faqData={faqData}
        viewMode="viewerPage"
        publicId={publicId}
        categoryNumber={categoryNumber}
      />
    </div>
  );
};

export default MiniFaq;
