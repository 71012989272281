import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";

const ContentCard = ({ cardData, onEdit, onDelete }) => {
  const handleCardClick = () => {
    onEdit(cardData.id);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    onDelete(cardData.id);
  };

  return (
    <div className="content-card" onClick={handleCardClick}>
      {cardData ? (
        <>
          <div className="card-data">
            <h3>{cardData.title}</h3>
          </div>
          <div className="card-actions">
            <button onClick={handleDeleteClick}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </>
      ) : (
        <div className="empty-card">No data</div>
      )}
    </div>
  );
};

export default ContentCard;
