import React, { useEffect, useState } from "react";
import FAQContainer from "./FAQContainer";
import ChatBot from "./ChatBot";
import "./ViewerPage.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTheme } from "./ThemeContext"; // Ensure this import is correct

const ViewerPage = ({ faqData, viewMode }) => {
  const { theme } = useTheme(); // Add this line to use the theme context
  const { publicId } = useParams(); // Access the publicId from the URL
  const [messages, setMessages] = useState([]);
  const [contentCards, setContentCards] = useState([]);
  const [faqs, setFaqs] = useState([]); // State to hold FAQ data
  const [pageName, setPageName] = useState("Help"); // Default value
  const [categoryNumber, setCategoryNumber] = useState([]); // Default value
  const [banner, setBanner] = useState("https://i.imgur.com/2pPQeS6.png"); // Default value
  const [chatbotActive, setChatbotActive] = useState(false); // this is for mobile toggle
  const [isChatbotEnabled, setIsChatbotEnabled] = useState(false); // State to hold if chatbot is enabled globally

  // Override isSignedIn to always be false
  const isSignedIn = false;

  // Retrieve chat messages from session storage when component mounts
  useEffect(() => {
    const storedMessages = sessionStorage.getItem("chatMessages");
    setMessages(storedMessages ? JSON.parse(storedMessages) : []);
  }, []);

  // Fetch content cards when component mounts
  useEffect(() => {
    const fetchContentCards = async () => {
      try {
        const response = await axios.get("/api/content-cards", {
          params: { publicId: publicId },
        });

        setContentCards(response.data.contentCards || []);
      } catch (error) {
        console.error("Error fetching content cards:", error);
      }
    };

    fetchContentCards();
  }, []);

  // Fetch FAQ data when component mounts
  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        console.log("Theme:", theme);
        const response = await axios.get("/api/faqs", {
          params: { publicId: publicId },
        });

        const sortedFaqs = response.data.sort(
          (a, b) => parseInt(b.id) - parseInt(a.id)
        );

        setFaqs(sortedFaqs);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFAQs();
  }, []);

  // Fetch page metadata (pageName and banner) when component mounts
  useEffect(() => {
    const fetchPageMetaData = async () => {
      try {
        const response = await axios.get("/api/meta-data", {
          params: { publicId: publicId },
        });

        setPageName(response.data.pageName || "Help");
        setBanner(response.data.banner || "https://i.imgur.com/2pPQeS6.png");
        setCategoryNumber(response.data.categoryNumber);
        setChatbotActive(response.data.chatbot);
        setIsChatbotEnabled(response.data.chatbot); // Also use this to control the rendering of the chatbot and toggle button
        localStorage.setItem("categoryNumber", response.data.categoryNumber);
        console.log("isChatbot 2", response.data.chatbot);
        console.log("isChatbot 2", response.data.categoryNumber);
      } catch (error) {
        console.error("Error fetching page metadata:", error);
      }
    };

    fetchPageMetaData();
  }, [publicId]);

  // Define a placeholder function for onChatGPTData when viewMode is viewerPage
  const onChatGPTData = () => {
    console.warn(
      "Warning: onChatGPTData function not passed down in viewerPage"
    );
  };

  const toggleChatbot = () => {
    setChatbotActive(!chatbotActive);
  };

  const closeChatbot = () => {
    setChatbotActive(false);
  };

  //all the analytics logging
  function generateUniqueVisitorId() {
    return "visitor-" + Math.random().toString(36).substr(2, 9);
  }

  useEffect(() => {
    let visitorId = localStorage.getItem("visitorId");
    if (!visitorId) {
      visitorId = generateUniqueVisitorId(); // You need to define this function to generate a unique ID
      localStorage.setItem("visitorId", visitorId);
    }

    axios
      .post("/api/log-visit", { publicId, visitorId })
      .catch((error) => console.error("Error logging visit", error));
  }, []);

  return (
    <div className={`viewer-app-container ${theme}`}>
      <div
        className="ViewerHeader"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <h2>{pageName}</h2>
      </div>
      <div className="viewer-content">
        <div className="viewer-faq-column">
          <FAQContainer
            faqData={faqData}
            viewMode={viewMode}
            publicId={publicId}
            categoryNumber={categoryNumber}
            isSignedIn={isSignedIn} // Always pass false
          />
        </div>
        {isChatbotEnabled && (
          <>
            {chatbotActive && (
              <div className="viewer-chatbot-container active">
                <button
                  onClick={() => setChatbotActive(false)}
                  className="chatbot-close-btn"
                >
                  X
                </button>
                <h2>👨🏼‍💼 Ask me anything!</h2>
                <ChatBot
                  onChatGPTData={() => console.log("ChatGPT data")}
                  viewMode={viewMode}
                  resetMode={true}
                  contentCards={contentCards}
                  faqData={faqs}
                />
              </div>
            )}
            <button onClick={toggleChatbot} className="chatbot-toggle">
              Chat with us
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewerPage;
