import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import axios from "axios";
import { useParams } from "react-router-dom";

const FAQItem = ({
  id,
  question,
  answer,
  faq,
  isSignedIn,
  viewMode,
  onDelete,
  onEdit,
  toggleOpen,
  isOpen,
  hasNoAnswer,
}) => {
  console.log("SignedIn from FAQ Item:", isSignedIn);
  const answerRef = useRef(null);
  const wrapperRef = useRef(null);
  const { publicId } = useParams(); // Access the publicId from the URL

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    onDelete(faq.id);
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    onEdit(faq);
  };

  const logFaqClick = async () => {
    console.log("FAQ PAGE:", isSignedIn);
    if (!isSignedIn) {
      try {
        await axios.post(`/api/log-faq-click/${publicId}`, {
          publicId,
          faqId: faq.id,
        });
        console.log(`FAQ click logged for FAQ ID: ${faq.id}`);
      } catch (error) {
        console.error("Failed to log FAQ click", error);
      }
    }
  };

  const logInnerLinkClick = (linkData) => {
    if (!isSignedIn) {
      axios
        .post(`/api/log-inner-link-click`, linkData)
        .catch((error) =>
          console.error("Failed to log inner link click", error)
        );
    }
  };

  useEffect(() => {
    if (isOpen) {
      wrapperRef.current.style.height = `${answerRef.current.scrollHeight}px`;
      logFaqClick(); // Log the click when FAQ is opened
    } else {
      wrapperRef.current.style.height = "0px";
    }

    const handleLinkClick = (e) => {
      const target = e.target;
      if (target.tagName === "A") {
        e.stopPropagation(); // Stop the click event from propagating
        const linkData = {
          publicId,
          faqId: faq.id,
          href: target.href,
          type: target.href.startsWith("mailto:") ? "mailto" : "link",
        };
        logInnerLinkClick(linkData);

        console.log("click captured!:", linkData); // Console log when a link is clicked
      }
    };

    // Attach event listeners to links within the answer
    const answerElement = answerRef.current;
    if (answerElement) {
      answerElement.addEventListener("click", handleLinkClick);
    }

    // Cleanup event listeners on unmount
    return () => {
      if (answerElement) {
        answerElement.removeEventListener("click", handleLinkClick);
      }
    };
  }, [isOpen, faq, publicId, isSignedIn]);

  return (
    <div
      className={`faq-item ${isOpen ? "open" : ""} ${
        hasNoAnswer ? "faq-item-no-answer" : ""
      }`}
      onClick={toggleOpen} // Ensure this calls toggleOpen
    >
      {faq && (
        <>
          <div className="faq-question">
            <span>{question}</span>
            <span className="arrow">{isOpen ? "▴" : "💬"}</span>
          </div>
          <div className="faq-answer-wrapper" ref={wrapperRef}>
            <div
              className="faq-answer"
              ref={answerRef}
              dangerouslySetInnerHTML={{ __html: answer }}
            />
          </div>
        </>
      )}
      {isOpen && viewMode !== "viewerPage" && (
        <div className="faq-actions">
          <button onClick={handleDeleteClick} className="delete-button">
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
          <button onClick={handleEditClick} className="edit-button">
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      )}
    </div>
  );
};

export default FAQItem;
