import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const TextEditor = ({ value, onChange }) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null); // To hold the Quill instance

  useEffect(() => {
    if (!editorRef.current) return; // Do nothing if ref is not attached

    const editor = editorRef.current;
    if (!quillRef.current) {
      quillRef.current = new Quill(editor, {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"], // toggled buttons
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ align: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults
            [{ font: [] }],
            ["link", "image", "video"], // link and image, video
          ],
        },
      });

      quillRef.current.on("text-change", () => {
        const html = quillRef.current.root.innerHTML;
        if (html !== value) {
          onChange(html);
        }
      });
    }

    return () => {
      quillRef.current = null;
    };
  }, []); // Ensure this runs only once

  useEffect(() => {
    if (quillRef.current && quillRef.current.root.innerHTML !== value) {
      quillRef.current.root.innerHTML = value;
    }
  }, [value]); // Effect to update editor content when external value changes

  return <div ref={editorRef} />;
};

export default TextEditor;
