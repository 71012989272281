import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./Header";
import axios from "axios";
import ListContentCards from "./ListContentCards";
import FAQContainer from "./FAQContainer";
import ViewerPage from "./ViewerPage";
import SignIn from "./SignIn";
import PageMetaData from "./PageMetaData";
import Analytics from "./Analytics";
import DemoPage from "./DemoPage";
import MiniFaq from "./MiniFaq";
import { ThemeProvider } from "./ThemeContext";
import "./styles.css";
import "./ContentCard.css";
import "quill/dist/quill.snow.css"; // For snow theme

const App = () => {
  const [signedIn, setSignedIn] = useState(false);
  const [viewerURL, setViewerURL] = useState("");
  const [contentCards, setContentCards] = useState([]);
  const [viewMode, setViewMode] = useState("");
  const [isEmbed, setIsEmbed] = useState(false);
  const [showSignInModule, setShowSignInModule] = useState(false);
  const [hasFaqs, setHasFaqs] = useState(false); // New state for managing if FAQs exist

  const handleSignIn = () => {
    console.log("Sign in successful");
    setSignedIn(true);
    localStorage.setItem("signedIn", "true");
    setShowSignInModule(false); // Close the modal after sign in
  };

  const handleSignOut = () => {
    localStorage.removeItem("signedIn");
    localStorage.removeItem("userEmail");
    setSignedIn(false);
    window.location.href = "/"; // Redirect to the root of the page
  };

  const openSignInModule = () => {
    setShowSignInModule(true);
  };

  const closeSignInModule = () => {
    setShowSignInModule(false);
  };

  const handleViewLiveClick = () => {
    const baseURL = "/view?email=";
    const url = `${baseURL}${encodeURIComponent("")}`;
    setViewerURL(url);
    setViewMode("viewerPage");
  };

  useEffect(() => {
    const isSignedIn = localStorage.getItem("signedIn") === "true";
    console.log("Is signed in:", signedIn);
    setSignedIn(isSignedIn);

    const savedContentCards = localStorage.getItem("contentCards");
    if (savedContentCards) {
      setContentCards(JSON.parse(savedContentCards));
    }
  }, []);

  const handleSaveData = async (data) => {
    try {
      await axios.post("/api/save", data);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleSignUp = async (data) => {
    try {
      const response = await axios.post("/api/signup", data);
      handleSignIn();
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  // Function to conditionally render the header
  const renderHeader = (pathname) => {
    // Render header only if the pathname is not /demo/:publicId or /view/:publicId
    if (!pathname.startsWith("/demo/") && !pathname.startsWith("/view/")) {
      return (
        <Header
          onSignOut={handleSignOut}
          signedIn={signedIn}
          onViewLiveClick={handleViewLiveClick}
          onSignInClick={openSignInModule}
          onSignUpClick={openSignInModule}
          hasFaqs={hasFaqs} // Pass hasFaqs state to Header
        />
      );
    }
    return null;
  };

  return (
    <Router>
      {/* Conditionally render the header */}
      {viewMode !== "viewerPage" &&
        !isEmbed &&
        renderHeader(window.location.pathname)}
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Navigate to="/analytics" />} />
          <Route
            path="/knowledge"
            element={
              signedIn ? (
                <ListContentCards
                  viewMode={viewMode}
                  onSaveData={handleSaveData}
                  contentCards={contentCards}
                  setContentCards={setContentCards}
                />
              ) : (
                <DemoPage onSignIn={handleSignIn} />
              )
            }
          />
          <Route
            path="/analytics"
            element={
              signedIn ? <Analytics /> : <DemoPage onSignIn={handleSignIn} />
            }
          />
          <Route
            path="/settings"
            element={
              signedIn ? <PageMetaData /> : <DemoPage onSignIn={handleSignIn} />
            }
          />
          <Route
            path="/faq"
            element={
              signedIn ? (
                <FAQContainer
                  faqData={null}
                  viewMode={viewMode}
                  setHasFaqs={setHasFaqs} // Pass setHasFaqs to FAQContainer
                  isSignedIn={signedIn}
                />
              ) : (
                <DemoPage onSignIn={handleSignIn} />
              )
            }
          />
          <Route
            path="/view/:publicId"
            element={
              <ThemeProvider>
                <ViewerPage viewMode="viewerPage" isSignedIn={signedIn} />
              </ThemeProvider>
            }
          />
          <Route
            path="/demo/:publicId"
            element={
              <ThemeProvider>
                <MiniFaq />
              </ThemeProvider>
            }
          />
        </Routes>
        {viewerURL && (
          <p>
            Viewer URL: <a href={viewerURL}>{viewerURL}</a>
          </p>
        )}
        <SignIn
          showSignInModule={showSignInModule}
          closeSignInModule={closeSignInModule}
          onSignIn={handleSignIn}
        />
      </div>
    </Router>
  );
};

export default App;
