import React, { useEffect, useState } from "react";
import FAQContainer from "./FAQContainer";
import "./DemoPage.css";
import axios from "axios";
import "./Embed.css";
import Analytics from "./Analytics";
import FloatingSquares from "./FloatingSquares";
import SignIn from "./SignIn"; // Import the SignIn component

const DemoPage = ({ onSignIn }) => {
  // Receive onSignIn as a prop
  const [isEmbed, setIsEmbed] = useState(false);
  const [messages, setMessages] = useState([]);
  const [contentCards, setContentCards] = useState([]);
  const [faqs, setFaqs] = useState([]); // State to hold FAQ data
  const [pageName, setPageName] = useState("Help"); // Default value
  const [categoryNumber, setCategoryNumber] = useState([]); // Default value
  const [banner, setBanner] = useState("https://i.imgur.com/2pPQeS6.png"); // Default value
  const [chatbotActive, setChatbotActive] = useState(false);
  const [buttonOffset, setButtonOffset] = useState(0);
  const [displaySubheader, setDisplaySubheader] = useState(false);
  const [displayCTA, setDisplayCTA] = useState(true); // Display CTA by default
  const [headerTextPart1, setHeaderTextPart1] = useState("");
  const [headerTextPart2, setHeaderTextPart2] = useState("");
  const [showSignInModule, setShowSignInModule] = useState(false); // State to manage the visibility of the sign-in module
  const fullHeaderTextPart1 = "Automate FAQ,";
  const fullHeaderTextPart2 = "grow your business";
  const [initialSignInMode, setInitialSignInMode] = useState("signin"); // State to manage the initial mode of the sign-in modal

  useEffect(() => {
    // Add the 'animate' class after a short delay when the component mounts
    const button = document.querySelector(".start-button");
    setTimeout(() => {
      button.classList.add("animate");
    }, 500); // Adjust the delay as needed
  }, []);

  useEffect(() => {
    let index1 = 0;
    let index2 = 0;
    const typewriterInterval = setInterval(() => {
      if (index1 < fullHeaderTextPart1.length) {
        setHeaderTextPart1(fullHeaderTextPart1.slice(0, index1 + 1));
        index1++;
      } else if (index2 < fullHeaderTextPart2.length) {
        setHeaderTextPart2(fullHeaderTextPart2.slice(0, index2 + 1));
        index2++;
      } else {
        clearInterval(typewriterInterval);
        setTimeout(() => setDisplaySubheader(true), 500); // Delay for subheader
      }
    }, 100);

    return () => clearInterval(typewriterInterval);
  }, []);

  useEffect(() => {
    if (displaySubheader) {
      setTimeout(() => setDisplayCTA(true), 500); // Delay for CTA button
    }
  }, [displaySubheader]);

  useEffect(() => {
    const button = document.querySelector(".start-button");
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const delta = currentScrollY - lastScrollY;
      lastScrollY = currentScrollY;
      setButtonOffset((prevOffset) => prevOffset + delta * 0.1);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSignIn = (mode = "signin") => {
    setInitialSignInMode(mode);
    setShowSignInModule(true);
  };

  const closeSignInModule = () => {
    setShowSignInModule(false);
  };

  return (
    <div className="DemoPage">
      <FloatingSquares />
      <div className="header-container">
        <h1>
          {headerTextPart1}
          <br />
          {headerTextPart2}
        </h1>
        {displaySubheader && (
          <h2>
            Boost user sign-ups and gain deep insights with AI-driven FAQs.
          </h2>
        )}
        {displayCTA && (
          <button
            className="start-button"
            style={{
              transform: `translateY(${buttonOffset}px)`,
              transition: "transform 0.1s ease",
            }}
            onClick={() => handleSignIn("signup")}
          >
            Start now
          </button>
        )}
      </div>
      <div className="ContentBlock">
        <div className="FAQContainer">
          <h3>1. FAQ by Ai (Public View)</h3>
          <div className="FAQBlock">
            <iframe
              id="myiframe"
              src="/demo/Pf9yEFYd"
              width="730"
              height="700"
              frameBorder="0"
              scrolling="yes"
            ></iframe>
          </div>
        </div>
        <div className="AnalyticContainer">
          <h3>2. FAQ Metrics (Admin View)</h3>
          <div className="AnalyticBlock">
            <Analytics
              publicId="Pf9yEFYd"
              fromDemoPage={true}
              signedIn={false}
            />
          </div>
        </div>
      </div>
      <SignIn
        showSignInModule={showSignInModule}
        closeSignInModule={closeSignInModule}
        onSignIn={(email) => {
          console.log(`User signed in with email: ${email}`);
          onSignIn(); // Call the onSignIn function passed from App.js
        }}
        initialMode={initialSignInMode} // Pass the initial mode to the SignIn component
      />
    </div>
  );
};

export default DemoPage;
