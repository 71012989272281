import axios from "axios";

export const initiateFaqCleanup = async (faqs) => {
  console.log("Starting FAQ cleanup with:", faqs);
  const storedFaqClickData = localStorage.getItem("faqClickData");
  const companyData = JSON.parse(localStorage.getItem("contentCards"));
  const faqClickData = JSON.parse(storedFaqClickData || "{}");
  console.log("Content", companyData);
  console.log("FAQ Click Data for Cleanup:", faqClickData);
  console.log("faqClickDat Typf of:", typeof faqClickData);
  // Assuming faqClickData is an array of objects
  const faqClickObject = faqClickData.find((faq) => faq.id === 79534702); // Ensure the ID is of the correct type (number in this case)
  const clicks = faqClickObject ? faqClickObject.clicks : 0; // Default to 0 if the ID is not found
  console.log(`Clicks for ID 79534702: ${clicks}`);

  // Convert FAQ list into a JSON formatted string
  const faqJson = JSON.stringify(
    faqs.map((faq) => ({
      id: faq.id,
      question: faq.question,
      answer: faq.answer, // Assuming each FAQ has an 'answer' field
    })),
    null,
    2
  ); // The '2' argument in JSON.stringify pretty-prints the JSON

  const prompt = `
  Here is a list of frequently asked questions (FAQs).   ${faqJson}
`;

  const userPrompt = `
  The task is to identify duplicate questions based on **exact string matching**. Return a JSON array where each object contains a group of IDs that belong to duplicate questions.  

  **Important Guidelines:**
  * **Do Not Invent New IDs:** Only use IDs from the provided FAQs.
  * **No Modifications:** Do not modify the question text in any way.
  * **Verification Required:** Triple-check your results to ensure all returned IDs exist in the initial list and each ID appears only once across all groups.
  * **Error Checking:** Before you return your response, remove any errors such as:
    * **Duplicates in Multiple Groups:** Ensure no ID is part of more than one group.
    * **Logical Inconsistencies:** Verify that grouped questions are truly duplicates.
    * **Non-Sensical Groupings:** Avoid grouping unrelated questions.
  * **Strict Format Compliance:** Adhere to the specified JSON structure without adding extra characters or errors.
  *  combined two quesitons together into a single string variable call quesiton

**How to identify duplicate questions / simliarity ; 
Keyword match - if the question contains more than 50% of the same keywords
Evenif the intention and the gola of the question is same, if the syntax is different, do not combine them. 

[
  {
    ids: ["id1", "id2"],
    question: "question from id1 + question from id2"
  },
  ...
]
`;

  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [
          { role: "system", content: prompt },
          { role: "user", content: userPrompt },
        ],
        max_tokens: 4000,
        temperature: 0.1,
      }),
    });
    console.log("hello");
    const data = await response.json();
    console.log("first gpt response:", data);

    if (response.ok) {
      let jsonContent = data.choices[0].message.content;
      // This regex finds everything from the first '[' to the last ']' inclusive.
      jsonContent = jsonContent.match(/\[.*\]/s)[0];
      const idsToDelete = new Set(); // To track ids that will be merged and removed

      try {
        const duplicates = JSON.parse(jsonContent);
        console.log("Parsed duplicates:", duplicates);

        const combinedFAQs = [];
        duplicates.forEach((group) => {
          let maxClicks = -1;
          let idToKeep = null;
          let totalClicks = 0;
          let allQuestions = [];
          let allAnswers = [];

          group.ids.forEach((id) => {
            const faq = faqs.find(
              (faqItem) => faqItem.id.toString() === id.toString()
            );
            if (!faq) {
              console.error(`FAQ with ID ${id} not found.`);
              return;
            }

            // Find the click data for the current ID
            const faqClickObject = faqClickData.find(
              (clickData) => clickData.id.toString() === id.toString()
            );
            const clicks = faqClickObject ? faqClickObject.clicks : 0;

            console.log("For Id", id, " Clicks", clicks);

            totalClicks += clicks;

            if (id !== idToKeep) {
              idsToDelete.add(id); // Add all ids except the one to keep for deletion
            }

            allQuestions.push(faq.question);
            if (faq.answer) {
              allAnswers.push(faq.answer);
            }

            if (
              clicks > maxClicks ||
              (clicks === maxClicks &&
                (idToKeep === null || parseInt(id) < parseInt(idToKeep)))
            ) {
              if (idToKeep !== null) {
                idsToDelete.add(idToKeep); // Previous ID is not the max, so mark it for deletion
              }
              maxClicks = clicks;
              idToKeep = id;
              idsToDelete.delete(id); // Ensure the id to keep is not marked for deletion
            }
          });

          combinedFAQs.push({
            id: idToKeep,
            question: allQuestions.join(" "),
            answer: allAnswers.join(" "),
            totalClicks: totalClicks,
          });
        });

        console.log("Combined FAQs:", combinedFAQs);
        console.log("IDs to delete:", Array.from(idsToDelete)); // Log the IDs that should be deleted
        //this is where we send back the combined FAQS along with the company data to get the combined question and combined answers.

        // Assuming you have already fetched and parsed your contentCards from localStorage
        const contentCards = JSON.parse(localStorage.getItem("contentCards"));

        // Constructing the messages array from the contentCards data
        const contentCardMessages = contentCards.map((card) => ({
          content: `Title: ${card.title}, Description: ${card.description}`,
        }));

        // Now, contentCardMessages is ready to be used wherever needed
        console.log(contentCardMessages);

        const prompt = `
    Here is a list of combined frequently asked questions (FAQs) and relevant company data. Your task is to refine and rephrase each FAQ into a clear, concise question and answer format based on the provided data. Ensure each new FAQ is relevant to the company's current policies and services as described in the attached company data.
  
    Combined FAQs:
    ${combinedFAQs
      .map(
        (faq) =>
          `- ID ${faq.id}: Questions: ${faq.question} Answers: ${faq.answer}`
      )
      .join("\n")}
  
    Company Data:
    ${JSON.stringify(contentCardMessages, null, 2)}
  
    Please rephrase the FAQs to ensure they are up-to-date and aligned with the company information provided. Return the data in the following JSON format:
  
    [
      {
        "id": "ID of FAQ",
        "question": "Refined and updated question",
        "answer": "Refined and updated answer"
      },
      ...
    ]
  `;

        console.log("prompt:", prompt);
        // Example function call to fetch API or similar functionality
        fetch("https://api.openai.com/v1/chat/completions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [{ role: "system", content: prompt }],
            max_tokens: 4000,
            temperature: 0.1,
          }),
        })
          .then((response) => response.json()) // Parse the response body as JSON
          .then((data) => {
            if (data.choices && data.choices.length > 0) {
              try {
                let jsonContent = JSON.parse(
                  data.choices[0].message.content.match(/\[.*\]/s)[0]
                );

                // Prepare updates for MongoDB bulk operation
                const updates = jsonContent.map((faq) => ({
                  updateOne: {
                    filter: { id: parseInt(faq.id) }, // Convert ID to integer if necessary
                    update: {
                      $set: { question: faq.question, answer: faq.answer },
                    },
                  },
                }));

                console.log("Updates ready for bulk operation:", updates);
                const userEmail = localStorage.getItem("userEmail");
                // Send the bulk update request to the server
                axios
                  .post(
                    "/api/faqs/updateBulk",
                    { updates },
                    {
                      params: { email: userEmail }, // Ensure this is dynamically set based on the user context
                    }
                  )
                  .then((response) => {
                    console.log("Bulk update response:", response.data);
                    // After successful update, call deleteFAQs
                    const idsToDelete = new Set(
                      jsonContent.map((faq) => faq.id)
                    ); // Prepare ids for deletion
                    deleteFAQs(Array.from(idsToDelete), userEmail);
                  })
                  .catch((error) => {
                    console.error("Bulk update error:", error);
                  });
              } catch (error) {
                console.error("Error parsing JSON content:", error);
              }
            } else {
              console.log("No choices available in response.");
            }
          })
          .catch((error) => {
            console.error("Error processing FAQs:", error);
          });

        // Function to delete FAQs
        async function deleteFAQs(idsToDelete, userEmail) {
          if (idsToDelete.length === 0) {
            console.log("No IDs provided for deletion.");
            return;
          }

          console.log("Preparing to delete IDs:", idsToDelete);

          // Confirm deletion logic here or log detailed actions
          try {
            // Make sure that the idsToDelete is always an array of integers (if IDs are stored as integers in DB)
            const idsToDeleteParsed = idsToDelete.map((id) => parseInt(id));

            const response = await axios.post(
              "/api/faqs/deleteBulk",
              { ids: idsToDeleteParsed },
              {
                params: { email: userEmail },
              }
            );

            console.log("Bulk deletion response:", response.data);
            if (response.status === 200) {
              // Refresh the page to reflect changes
              window.location.reload();
            }
          } catch (error) {
            console.error("Bulk deletion error:", error);
          }
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        console.error("Faulty JSON content:", data.choices[0].message.content);
      }

      //Now we haver everything ready. We need to call Server to update and delete from the database.
      // Function to update FAQs based on ChatGPT responses
    } else {
      throw new Error(
        data.error ? data.error.message : "No error message available"
      );
    }
  } catch (error) {
    console.error("Error during FAQ cleanup:", error);
  }
};
