import React, { useEffect, useRef } from "react";
import "./FloatingSquares.css";

const FloatingSquares = () => {
  const containerRef = useRef(null);
  const squaresRef = useRef([]);

  useEffect(() => {
    const container = containerRef.current;
    const minSpeed = 3; // Minimum speed for the squares
    const size = 300; // Size of each square

    const createSquare = (index, className) => {
      const square = document.createElement("div");
      square.className = `floating-square ${className}`;
      // Start within boundaries considering square size
      square.style.left = `${Math.random() * (window.innerWidth - size)}px`;
      square.style.top = `${Math.random() * (window.innerHeight - size)}px`;
      // Set movement speed
      square.dx = (Math.random() * 2 - 1) * minSpeed;
      square.dy = (Math.random() * 2 - 1) * minSpeed;
      square.rotation = Math.random() * 360;
      container.appendChild(square);
      squaresRef.current[index] = square;
    };

    createSquare(0, "square1");
    createSquare(1, "square2");
    createSquare(2, "square3");
    createSquare(3, "square4");

    const animate = () => {
      squaresRef.current.forEach((square) => {
        let left = parseFloat(square.style.left);
        let top = parseFloat(square.style.top);

        // Reverse direction upon hitting edges, considering square size
        if (left + size > window.innerWidth || left < 0) {
          square.dx *= -1;
        }
        if (top + size > window.innerHeight || top < 0) {
          square.dy *= -1;
        }

        // Update position
        square.style.left = `${left + square.dx}px`;
        square.style.top = `${top + square.dy}px`;
        square.rotation += 0.5; // Adjust rotation speed if necessary
        square.style.transform = `rotate(${square.rotation}deg)`;
      });

      requestAnimationFrame(animate);
    };

    animate();
  }, []);

  return <div className="floating-squares-container" ref={containerRef}></div>;
};

export default FloatingSquares;
