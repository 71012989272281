import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import FAQItem from "./FAQItem";
import Modal from "./FAQModal";
import { initiateFaqCleanup } from "./FaqCleanup";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import generateKeywords from "./GenerateKeywords"; // Import the generateKeywords function
import "./ContentCard.css";
import { Link } from "react-router-dom";

const FAQList = ({
  viewMode,
  categoryNumber,
  isEmbed,
  setHasFaqs,
  isSignedIn,
}) => {
  const { publicId: publicIdFromUrl } = useParams();
  const [faqs, setFaqs] = useState([]);
  const { publicId } = useParams(); // Access the publicId from the URL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("text");
  const [currentFAQ, setCurrentFAQ] = useState({
    id: null,
    question: "",
    answer: "",
  });
  const [confirmMessage, setConfirmMessage] = useState("");
  const [categories, setCategories] = useState([]); // State to store categories
  const [selectedCategories, setSelectedCategories] = useState([]); // State to store selected categories
  const [categoryToIdsMap, setCategoryToIdsMap] = useState({}); // State to store category to FAQ IDs map
  const [isLoading, setIsLoading] = useState(false); // State to manage loading
  const listRef = useRef(null);
  const [sortedFaqs, setSortedFaqs] = useState([]); // State to hold sorted FAQs
  const [isLoadingFaqs, setIsLoadingFaqs] = useState(false);
  const [sortedTags, setSortedTags] = useState([]); // State to hold sorted FAQs
  const [selectedTags, setSelectedTags] = useState([]); // State to store selected tags
  const [openFaqId, setOpenFaqId] = useState(null); // State to track the open FAQ
  const [selectedFont, setSelectedFont] = useState("Inter"); // Default font
  const [isInitialLoading, setIsInitialLoading] = useState(true); // State to manage initial loading

  // Fetch page metadata (pageName and banner) when component mounts
  useEffect(() => {
    const fetchPageMetaData = async () => {
      try {
        const response = await axios.get("/api/meta-data", {
          params: { publicId: publicId },
        });

        setSelectedFont(response.data.font);
        console.log("font:", response.data.font); // Log the font after it is fetched and set
      } catch (error) {
        console.error("Error fetching page metadata:", error);
      }
    };
    fetchPageMetaData();
  }, [publicId]); // Dependency on publicId ensures this runs only when publicId changes

  // Load the font using a stylesheet
  useEffect(() => {
    if (selectedFont && selectedFont !== "Inter") {
      // Only proceed if the font has been updated from the default
      const style = document.createElement("style");
      document.head.appendChild(style);
      console.log("font3:", selectedFont); // Log the font only after it has been updated from the default
      style.innerHTML = `
      @import url('https://fonts.googleapis.com/css?family=${selectedFont}&display=swap');
      body * {
        font-family: '${selectedFont}' !important;
      }
    `;
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [selectedFont]); // Dependency on selectedFont ensures this runs only when selectedFont changes

  useEffect(() => {
    const fetchFAQs = async () => {
      setIsLoading(true); // Start loading
      try {
        let publicId;
        if (viewMode !== "viewerPage") {
          publicId = localStorage.getItem("publicId");
        } else {
          publicId = publicIdFromUrl;
        }

        if (isEmbed) {
          publicId = publicIdFromUrl; // Set specific public ID if isEmbed is true
        }

        const response = await axios.get("/api/faqs", {
          params: { publicId: publicId },
        });

        // Filter FAQs to only include those with non-empty answers
        let filteredFaqs;
        if (viewMode === "viewerPage" || isEmbed) {
          filteredFaqs = response.data.filter(
            (faq) => faq.answer && faq.answer.trim() !== ""
          );
        } else {
          filteredFaqs = response.data;
        }

        const sortedFaqs = filteredFaqs.sort((a, b) => a.order - b.order);
        setFaqs(sortedFaqs);

        // Save FAQs to local storage
        localStorage.setItem("faqs", JSON.stringify(sortedFaqs));

        // Update hasFaqs state if setHasFaqs is a function
        if (typeof setHasFaqs === "function") {
          setHasFaqs(sortedFaqs.length > 0);
        }

        // Extract tags and sort them by frequency
        const faqs = JSON.parse(localStorage.getItem("faqs") || "[]");
        let allTags = [];
        const tagCount = {};

        faqs.forEach((faq) => {
          if (faq.tags && Array.isArray(faq.tags)) {
            allTags = allTags.concat(faq.tags);
          }
        });

        allTags.forEach((tag) => {
          tag = tag.toLowerCase(); // Normalize tags to lower case for consistency
          if (tagCount[tag]) {
            tagCount[tag]++;
          } else {
            tagCount[tag] = 1;
          }
        });

        const sortedTagsArray = Object.entries(tagCount).sort(
          (a, b) => b[1] - a[1]
        );
        setSortedTags(sortedTagsArray);
      } catch (error) {
        console.error("Failed to fetch FAQs:", error);
      } finally {
        setIsLoading(false); // End loading
        setIsInitialLoading(false); // Set initial loading to false after data fetch
      }
    };

    fetchFAQs();
  }, [viewMode, publicIdFromUrl, isEmbed, setHasFaqs]); // Dependency array

  const handleCleanupClick = async () => {
    initiateFaqCleanup(faqs); // Assuming 'faqs' is your current list of FAQs
  };

  const sortFaqs = async (faqs) => {
    try {
      // Serialize FAQs to JSON
      const faqJson = JSON.stringify(
        faqs.map((faq) => ({
          id: faq.id,
          question: faq.question,
        })),
        null,
        2 // Makes the JSON easier to read in the prompt if logged
      );
      // Retrieve additional analytics data from local storage
      const storedFaqClickData = localStorage.getItem("faqClickData");
      const faqClickData = JSON.parse(storedFaqClickData || "[]").map(
        ({ id, clicks }) => ({ id, clicks })
      );

      const storedCategoryClickData = localStorage.getItem("categoryClickData");
      const categoryClickData = JSON.parse(storedCategoryClickData || "{}");

      // You might also want to include visitor analytics data if relevant
      const storedVisitorData = localStorage.getItem("visitorData");
      const visitorData = JSON.parse(storedVisitorData || "{}");

      // Construct the allData localStorage to use it anywhere in the app. This contains, all the analytics information and FAQs.
      const allData = {
        faqs: faqs.map((faq) => ({
          id: faq.id,
          question: faq.question,
        })),
        faqClicks: faqClickData,
        categoryClickData: categoryClickData,
        visitorData: visitorData,
      };

      // Save to localStorage
      localStorage.setItem("allData", JSON.stringify(allData));
    } catch (error) {
      console.error("Error sorting FAQs with ChatGPT:", error);
      return faqs; // Return original list on error
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentFAQ({ id: null, question: "", answer: "" });
  };

  const handleAddFAQ = () => {
    setCurrentFAQ({ id: null, question: "", answer: "" });
    setModalType("text");
    openModal();
  };

  const handleEditFAQ = (faq) => {
    setCurrentFAQ(faq);
    setModalType("text");
    openModal();
  };

  const handleDeleteFAQ = (faqId) => {
    setConfirmMessage("Are you sure you want to delete this FAQ?");
    setCurrentFAQ(faqs.find((faq) => faq.id === faqId));
    setModalType("confirm");
    openModal();
  };

  const confirmDelete = async (confirm) => {
    if (confirm) {
      try {
        const email = localStorage.getItem("userEmail");
        await axios.delete(
          `/api/faqs/${currentFAQ.id}?email=${encodeURIComponent(email)}`
        );

        const updatedFAQs = faqs.filter((faq) => faq.id !== currentFAQ.id);
        setFaqs(updatedFAQs);

        // Update hasFaqs state if setHasFaqs is a function
        if (typeof setHasFaqs === "function") {
          setHasFaqs(updatedFAQs.length > 0);
        }
      } catch (error) {
        console.error("Failed to delete FAQ:", error);
      }
    }
    closeModal();
  };

  const handleSaveFAQ = async (faqData) => {
    if (modalType === "text") {
      const userEmail = localStorage.getItem("userEmail");

      if (!userEmail) {
        console.error("User email is missing!");
        return;
      }

      if (currentFAQ && currentFAQ.id) {
        // Existing FAQ, handle update
        try {
          const response = await axios.put(
            `/api/faqs/${currentFAQ.id}?email=${encodeURIComponent(userEmail)}`,
            faqData
          );

          const updatedFAQs = faqs.map((faq) =>
            faq.id === currentFAQ.id ? { ...faq, ...faqData } : faq
          );
          setFaqs(updatedFAQs);

          // Update hasFaqs state if setHasFaqs is a function
          if (typeof setHasFaqs === "function") {
            setHasFaqs(updatedFAQs.length > 0);
          }
        } catch (error) {
          console.error("Failed to update FAQ:", error);
        }
      } else {
        // New FAQ, handle add
        try {
          function generateID() {
            return Math.floor(10000000 + Math.random() * 90000000);
          }
          const newID = generateID();
          const response = await axios.post("/api/faqs", {
            id: newID,
            question: faqData.question,
            answer: faqData.answer,
            tags: faqData.tags,
            email: userEmail,
          });

          const newFAQ = { ...faqData, id: response.data.id };
          const updatedFAQs = Array.isArray(faqs)
            ? [...faqs, newFAQ]
            : [newFAQ];

          setFaqs(updatedFAQs);

          // Update hasFaqs state if setHasFaqs is a function
          if (typeof setHasFaqs === "function") {
            setHasFaqs(updatedFAQs.length > 0);
          }
        } catch (error) {
          console.error("Failed to add FAQ:", error);
        }
      }
    }
    closeModal();
  };

  const handleTagClick = async (tag) => {
    // Toggle selection logic adjusted for radio-like behavior
    setSelectedTags((prevSelected) => {
      // If already selected, return empty array, otherwise select the new category exclusively
      return prevSelected.includes(tag) ? [] : [tag];
    });

    // Log category click if in 'viewerPage' mode
    if (viewMode === "viewerPage") {
      try {
        await axios.post("/api/log-category-click", {
          publicId: publicIdFromUrl,
          categoryName: tag,
        });
      } catch (error) {
        console.error("Error logging category click:", error);
      }
    }
  };

  const handleShowAllClick = () => {
    setSelectedTags([]);
  };

  const getFilteredFaqs = () => {
    if (selectedTags.length === 0) {
      return faqs;
    }

    return faqs.filter(
      (faq) =>
        Array.isArray(faq.tags) &&
        faq.tags.some((tag) => selectedTags.includes(tag.toLowerCase()))
    );
  };

  // Function to reorder FAQs after drag-and-drop
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newFaqs = reorder(
      filteredFaqs,
      result.source.index,
      result.destination.index
    );

    setFaqs(newFaqs);

    // Save the new order to the database
    saveOrderToDB(newFaqs);
  };

  const saveOrderToDB = async (faqs) => {
    try {
      const response = await axios.post("/api/faqs/reorder", {
        faqs: faqs.map((faq, index) => ({ id: faq.id, order: index })),
      });
      console.log("Order saved successfully:", response.data);
    } catch (error) {
      console.error("Failed to save order:", error);
    }
  };

  const handleGenerateKeywords = async () => {
    try {
      const result = await generateKeywords();
      console.log("Generated Keywords Result:", result);

      setCategories(result.flatCategoryString.split(", "));
      setCategoryToIdsMap(result.categoryToIdsMap);

      // Update FAQs with new tags
      for (const item of result.faqArray) {
        const tags = [item.category]; // Assume each FAQ has one category as tag
        for (const faqId of item.ids) {
          try {
            const userEmail = localStorage.getItem("userEmail");

            await axios.put(
              `/api/faqs/${faqId}?email=${encodeURIComponent(userEmail)}`,
              { tags } // Only send tags field to be updated
            );
          } catch (error) {
            console.error(`Failed to update FAQ with id ${faqId}:`, error);
          }
        }
      }
    } catch (error) {
      console.error("Failed to generate keywords:", error);
    }
  };

  const onTagUpdate = (updatedTags) => {
    setSortedTags(updatedTags);
    localStorage.setItem("categoryClickData", JSON.stringify(updatedTags));
  };

  const filteredFaqs = getFilteredFaqs();
  return (
    <>
      <div className="faq-list-container">
        {viewMode !== "viewerPage" && (
          <div className="subheader faq-buttons">
            <h2 className="page-title">My FAQ</h2>
            <div className="subheader-button-group">
              <label className="premium"> &nbsp;Pro</label>
              <button className="pro-button" onClick={handleCleanupClick}>
                <i data-feather="wind"></i> <span>Clean Up FAQs</span>
              </button>
              <button className="pro-button" onClick={handleGenerateKeywords}>
                <i data-feather="pen-tool"></i> <span>Generate Keywords</span>
              </button>
              <button className="pro-button" onClick={handleCleanupClick}>
                <i data-feather="layers"></i> <span>Magic Sort</span>
              </button>
              <button className="button-md addFaqButton" onClick={handleAddFAQ}>
                <i data-feather="plus-circle"></i> Add FAQ
              </button>
            </div>
          </div>
        )}
        {/* Empty state for FAQs */}
        {!isInitialLoading && faqs.length === 0 ? (
          <div>
            <h2 className="empty-faq-h2">Let’s Build Your FAQ!</h2>
            <div className="empty-state-container">
              <div className="empty-state">
                <h3>Batch Build with Ai</h3>
                <p>
                  Generate FAQs automatically from your company information in
                  the Knowledge section.
                </p>
                <Link to="/knowledge">
                  <button className="button-md addFaqButton link-button">
                    <i data-feather="plus-circle"></i> Add Knowledge
                  </button>
                </Link>
              </div>
              <div className="empty-state">
                <h3>Single Entry with Ai</h3>
                <p>Create FAQs manually by clicking the "Add FAQ" button.</p>
                <button
                  className="button-md addFaqButton"
                  onClick={handleAddFAQ}
                >
                  <i data-feather="plus-circle"></i> Add FAQ
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Display Categories */}
            <div className="categoryList">
              {viewMode !== "viewerPage" && (
                <div>
                  <h3 className="FAQH3">Keywords</h3>
                </div>
              )}
              {isLoadingFaqs || isLoading ? (
                <div className="loader">Loading Categories</div>
              ) : (
                <ul className="categoryPills">
                  {sortedTags.map(([tag, count], index) => (
                    <li key={index}>
                      <button
                        className={`categoryPill ${
                          selectedTags.includes(tag) ? "selected" : ""
                        }`}
                        onClick={() => handleTagClick(tag)}
                      >
                        {tag}
                      </button>
                    </li>
                  ))}
                  <li>
                    <button
                      className="categoryPill showAll"
                      onClick={handleShowAllClick}
                    >
                      Show All
                    </button>
                  </li>
                </ul>
              )}
            </div>
            {viewMode !== "viewerPage" && (
              <div>
                <h3 className="FAQH3-2">FAQ</h3>
              </div>
            )}
            {/* Display filtered FAQs with drag-and-drop if not in viewerPage mode and not embedded */}
            {viewMode !== "viewerPage" && !isEmbed ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="faqList">
                  {(provided) => (
                    <div
                      className="faqList"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {isLoadingFaqs || isLoading ? (
                        <div className="loader">Loading FAQs...</div>
                      ) : (
                        <ul className="faqItems">
                          {filteredFaqs.map((faq, index) => (
                            <Draggable
                              key={faq.id}
                              draggableId={faq.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="faq-item-container"
                                >
                                  <span
                                    className="draggable-handle"
                                    {...provided.dragHandleProps}
                                  />
                                  <FAQItem
                                    question={faq.question}
                                    answer={faq.answer}
                                    faq={faq}
                                    isOpen={openFaqId === faq.id}
                                    toggleOpen={() =>
                                      setOpenFaqId(
                                        openFaqId === faq.id ? null : faq.id
                                      )
                                    }
                                    isSignedIn={isSignedIn} // Assuming the user is always signed in
                                    viewMode={viewMode}
                                    onDelete={(id) => handleDeleteFAQ(id)}
                                    onEdit={(editedFaq) =>
                                      handleEditFAQ(editedFaq)
                                    }
                                    hasNoAnswer={
                                      viewMode !== "viewerPage" && !faq.answer
                                    } // New prop to indicate missing answers
                                  />
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <div className="faqList">
                {isLoadingFaqs || isLoading ? (
                  <div className="loader">Loading FAQs...</div>
                ) : (
                  <ul className="faqItems">
                    {filteredFaqs.map((faq) => (
                      <li key={faq.id} className="faq-item-container">
                        <FAQItem
                          question={faq.question}
                          answer={faq.answer}
                          faq={faq}
                          isOpen={openFaqId === faq.id}
                          toggleOpen={() =>
                            setOpenFaqId(openFaqId === faq.id ? null : faq.id)
                          }
                          isSignedIn={isSignedIn}
                          viewMode={viewMode}
                          onDelete={(id) => handleDeleteFAQ(id)}
                          onEdit={(editedFaq) => handleEditFAQ(editedFaq)}
                          hasNoAnswer={viewMode !== "viewerPage" && !faq.answer} // New prop to indicate missing answers
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </>
        )}

        {isModalOpen && (
          <Modal
            onClose={closeModal}
            onSave={modalType === "confirm" ? confirmDelete : handleSaveFAQ}
            existingData={currentFAQ}
            modalType={modalType}
            confirmMessage={confirmMessage}
            allTags={sortedTags} // Pass sortedTags here
            onTagUpdate={onTagUpdate} // Pass onTagUpdate function to Modal
          />
        )}
      </div>
    </>
  );
};

export default FAQList;
