import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./SignIn.css";

const SignIn = ({
  onSignIn,
  showSignInModule,
  closeSignInModule,
  initialMode = "signin", // Add an initialMode prop
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(initialMode === "signup"); // Set initial mode based on the prop
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [error, setError] = useState("");
  const [showResetMessage, setShowResetMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    setIsSignUp(initialMode === "signup");
  }, [initialMode]);

  const getHeaderTitle = () => {
    if (showResetMessage) {
      return "Reset Password";
    } else if (isSignUp) {
      return "Create Account";
    } else if (isForgotPassword) {
      return "Forgot Password";
    } else {
      return "Log in to your FAQio account";
    }
  };

  const handleSignInClick = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post("/api/signin", {
        email,
        password,
      });
      const { publicId } = response.data;

      localStorage.setItem("userEmail", email);
      localStorage.setItem("publicId", publicId);

      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError("");

      onSignIn(email);
      closeSignInModule();
      navigate("/knowledge"); // Navigate to the knowledge page
    } catch (error) {
      console.error("Error signing in:", error);
      setError(
        error.response?.data?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleSignUpClick = async () => {
    setIsLoading(true); // Start loading
    try {
      if (!email || !password || !confirmPassword) {
        setError("Please fill in all fields.");
        setIsLoading(false); // End loading
        return;
      }

      if (password !== confirmPassword) {
        setError("Passwords do not match.");
        setIsLoading(false); // End loading
        return;
      }

      const response = await axios.post("/api/signup", {
        email,
        password,
      });

      const { publicId } = response.data;

      localStorage.setItem("publicId", publicId);
      localStorage.setItem("userEmail", email);

      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError("");

      onSignIn(email);
      closeSignInModule();
      navigate("/knowledge"); // Navigate to the knowledge page
    } catch (error) {
      console.error("Error signing up:", error);
      setError(
        error.response?.data?.message ||
          "An unexpected error occurred. Please try again later."
      );
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleResetPasswordClick = async () => {
    try {
      await axios.post("/api/forgot-password", { email });
      setShowResetMessage(true);
      setEmail("");
      setError("");
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Silent error handling:", error);
    }
  };

  return (
    <>
      {showSignInModule && (
        <div className="dropBG" onClick={closeSignInModule}>
          <div
            className={showSignInModule ? "dropBG active" : "dropBG"}
            onClick={closeSignInModule}
          >
            <div
              className="form-container"
              style={{ textAlign: "center" }}
              onClick={(e) => e.stopPropagation()}
            >
              <button className="closeButton" onClick={closeSignInModule}>
                <span>×</span> {/* Unicode 'X' character */}
              </button>
              <div className="SignInLogo">
                <img
                  src="https://i.imgur.com/ZEBP9GL.png"
                  className="HeaderLogo"
                  alt="FAQIO Logo"
                />
                <h1 className="brand-name-2">FAQIO</h1>
              </div>
              <h1 className="brand-name1">{getHeaderTitle()}</h1>
              {!showResetMessage && (
                <div>
                  <label className="signinLabel">Email</label>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="email-input"
                  />
                </div>
              )}
              {!isForgotPassword && !showResetMessage && (
                <>
                  <div>
                    <label className="signinLabel">Password</label>
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="password-input"
                    />
                  </div>
                  {isSignUp && (
                    <div>
                      <label className="signinLabel">Confirm Password</label>
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="password-input"
                      />
                    </div>
                  )}
                </>
              )}
              {error && <div className="error-message">{error}</div>}
              {!isSignUp && !isForgotPassword && !showResetMessage && (
                <button
                  onClick={handleSignInClick}
                  className="start-button-sign"
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading ? "Signing In..." : "Sign In"}
                </button>
              )}
              {isSignUp && !showResetMessage && (
                <button
                  onClick={handleSignUpClick}
                  className="start-button-sign"
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading ? "Signing Up..." : "Sign Up"} //
                </button>
              )}
              {isForgotPassword && !showResetMessage && (
                <button
                  onClick={handleResetPasswordClick}
                  className="signin-button"
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading ? "Resetting..." : "Reset Password"} // Show
                  loading text
                </button>
              )}
              {showResetMessage && (
                <div>
                  <p>
                    Thank you, you will receive password reset instructions via
                    email.
                  </p>
                </div>
              )}
              <div className="text-links signin">
                {!isSignUp && !isForgotPassword && !showResetMessage && (
                  <div className="link-container">
                    <span
                      className="left-link"
                      onClick={() => {
                        setIsSignUp(true);
                        setError("");
                      }}
                    >
                      Create Account
                    </span>
                    <span
                      className="right-link"
                      onClick={() => {
                        setIsForgotPassword(true);
                        setShowResetMessage(false);
                        setError("");
                      }}
                    >
                      Forgot Password
                    </span>
                  </div>
                )}
                {isSignUp && !showResetMessage && (
                  <div className="link-container-solo">
                    <span
                      className="center-link"
                      onClick={() => {
                        setIsSignUp(false);
                        setError("");
                      }}
                    >
                      Sign In
                    </span>
                  </div>
                )}
                {!isSignUp && isForgotPassword && !showResetMessage && (
                  <div className="link-container-solo">
                    <span
                      className="center-link"
                      onClick={() => setIsForgotPassword(false)}
                    >
                      Cancel
                    </span>
                  </div>
                )}
                {showResetMessage && (
                  <p>
                    <a href="/">back</a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
