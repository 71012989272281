// PageMetaData.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles.css";
import "./PageMetaData.css";
import FontSelector from "./FontSelector"; // Adjust the path as necessary

const banners = [
  "https://i.imgur.com/L6tbKNv.png",
  "https://i.imgur.com/PvD8Epd.png",
  "https://i.imgur.com/WLkySK8.png",
  "https://i.imgur.com/RMwVjql.png",
  "https://i.imgur.com/p7PlZMf.png",
  "https://i.imgur.com/81Wbvri.jpeg",
  "https://i.imgur.com/vFeGZib.png",
  "https://i.imgur.com/b1tJvWg.png",
  "https://i.imgur.com/Muj0Z84.png",
  "https://i.imgur.com/n3pYkFq.png",
  "https://media.licdn.com/dms/image/D5616AQEDNU1p2lerPQ/profile-displaybackgroundimage-shrink_350_1400/0/1695903951555?e=1724284800&v=beta&t=WqjHH-lO-EkDcJyyhxVZ851WiYVqNzlSZ8GMDgtsY_Y",
];

const PageMetaData = () => {
  const [pageName, setPageName] = useState("");
  const [categoryNumber, setCategoryNumber] = useState("");
  const [selectedBanner, setSelectedBanner] = useState("");
  const [chatbot, setChatbot] = useState(false);
  const [selectedFont, setSelectedFont] = useState("");
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const publicId = localStorage.getItem("publicId");

    const fetchMetaData = async () => {
      try {
        const response = await axios.get("/api/meta-data", {
          params: { publicId },
        });
        const { pageName, banner, categoryNumber, chatbot, font, darkMode } =
          response.data;
        setPageName(pageName);
        setSelectedBanner(banner);
        setCategoryNumber(categoryNumber);
        setChatbot(chatbot);
        setSelectedFont(font);
        setDarkMode(darkMode);
      } catch (error) {
        console.error("Error fetching metadata:", error);
        alert("Failed to load page settings.");
      }
    };

    fetchMetaData();
  }, []);

  const handlePageNameChange = (e) => setPageName(e.target.value);
  const handleCategoryNumberChange = (e) => setCategoryNumber(e.target.value);
  const handleBannerSelect = (banner) => setSelectedBanner(banner);
  const handleChatBotChange = (e) => setChatbot(e.target.checked);
  const handleFontChange = (font) => setSelectedFont(font);
  const handleDarkModeChange = (e) => setDarkMode(e.target.checked);

  const handleSubmit = async () => {
    if (!pageName || !selectedBanner) {
      alert("Please enter a page name and select a banner.");
      return;
    }

    const email = localStorage.getItem("userEmail");

    const data = {
      pageName,
      banner: selectedBanner,
      email,
      categoryNumber,
      chatbot,
      font: selectedFont,
      darkMode,
    };

    try {
      await axios.put("/api/MetaData", data);
      alert("FAQ Page data saved successfully!");
    } catch (error) {
      console.error("Error saving FAQ Page data:", error);
      alert("Failed to save FAQ Page data.");
    }
  };

  return (
    <div className="pageMetaData">
      <div className="subheader faq-buttons">
        <h2 className="page-title">Public Page Settings</h2>
        <button className="button-md settings" onClick={handleSubmit}>
          Save
        </button>
      </div>
      <div className="page-settings-content">
        <div className="pageSettings">
          <label>Page Name</label>
          <input type="text" value={pageName} onChange={handlePageNameChange} />
        </div>
        <div className="pageSettings">
          <label>ChatBot Enabled?</label>
          <input
            type="checkbox"
            checked={chatbot}
            onChange={handleChatBotChange}
          />
        </div>
        <div className="pageSettings">
          <label>Dark Mode?</label>
          <input
            type="checkbox"
            checked={darkMode}
            onChange={handleDarkModeChange}
          />
        </div>
        <FontSelector selectedFont={selectedFont} onChange={handleFontChange} />
        <div className="bannerSetting">
          <label>Select Your Banner</label>
          {banners.map((banner, index) => (
            <img
              key={index}
              src={banner}
              alt={`Banner ${index + 1}`}
              className={`bannerImage ${
                selectedBanner === banner ? "selected" : ""
              }`}
              onClick={() => handleBannerSelect(banner)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageMetaData;
