import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./styles.css";
import "./Analytics.css";

const Analytics = ({ publicId, fromDemoPage, signedIn }) => {
  const [uniqueVisitors, setUniqueVisitors] = useState(0);
  const [totalVisits, setTotalVisitors] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isExpanded, setIsExpanded] = useState(fromDemoPage);
  const [expandedId, setExpandedId] = useState(null);
  const [faqClickData, setFaqClickData] = useState([]);
  const [categoryClickData, setCategoryClickData] = useState([]);
  const [chatHistories, setChatHistories] = useState([]);
  const [innerLinkClickData, setInnerLinkClickData] = useState([]);
  const [analysisResult, setAnalysisResult] = useState(""); // New state for analysis result

  // Avoid multiple renders by using a ref
  const isInitialMount = useRef(true);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      setIsLoading(true);
      try {
        if (!fromDemoPage) {
          publicId = localStorage.getItem("publicId");
        }
        console.log("Public Id inside Analytics.js:", publicId);
        const response = await axios.get(`/api/unique-visitors/${publicId}`);
        setUniqueVisitors(response.data.uniqueVisitors || 0);
        setTotalVisitors(response.data.totalVisits || 0);
        localStorage.setItem(
          "visitorData",
          JSON.stringify({
            uniqueVisitors: response.data.uniqueVisitors || 0,
            totalVisits: response.data.totalVisits || 0,
          })
        );
      } catch (error) {
        console.error("Failed to fetch analytics data:", error);
        setError("Failed to load data");
      } finally {
        setIsLoading(false);
      }
    };

    if (isInitialMount.current) {
      fetchAnalyticsData();
    }
  }, [publicId]);

  useEffect(() => {
    const fetchFaqClickData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/all-faqs-clicks/${publicId}`);
        setFaqClickData(response.data || []);
        localStorage.setItem(
          "faqClickData",
          JSON.stringify(response.data || [])
        );
      } catch (error) {
        console.error("Failed to load FAQ click data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isInitialMount.current) {
      fetchFaqClickData();
    }
  }, [publicId]);

  useEffect(() => {
    const fetchCategoryClickData = async () => {
      setIsLoading(true);
      try {
        console.log("Public Id we are sending to category clicks", publicId);
        const response = await axios.get(`/api/category-clicks/${publicId}`);
        const data = response.data.categoryClicks || {};
        let transformedData = [];
        if (data && typeof data === "object" && !Array.isArray(data)) {
          transformedData = Object.keys(data).map((key) => ({
            name: key,
            clicks: data[key] || 0,
          }));
        } else {
          transformedData = Array.isArray(data) ? data : [];
        }
        setCategoryClickData(transformedData);
      } catch (error) {
        console.error("Failed to load category click data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isInitialMount.current) {
      fetchCategoryClickData();
    }
  }, [publicId]);

  useEffect(() => {
    const fetchChatHistory = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/chat-history/${publicId}`);
        setChatHistories(response.data || []);
      } catch (error) {
        console.error("Failed to load chat history:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isInitialMount.current) {
      fetchChatHistory();
    }
  }, [publicId]);

  useEffect(() => {
    const fetchInnerLinkClickData = async () => {
      setIsLoading(true);
      try {
        publicId = localStorage.getItem("publicId");
        console.log("Public Id we are sending to link clicks", publicId);
        const response = await axios.get(`/api/inner-link-clicks/${publicId}`);
        const data = response.data.innerLinkClicks || {};
        console.log("Received inner link click data from server:", data);

        let transformedData = [];
        for (const [faqId, links] of Object.entries(data)) {
          console.log(`Processing FAQ ID: ${faqId}`);
          for (const [url, clicks] of Object.entries(links)) {
            console.log(`Processing URL: ${url} with ${clicks} clicks`);
            const faq = faqClickData.find((faq) => faq.id === parseInt(faqId));
            if (faq) {
              transformedData.push({
                clicks: clicks || 0,
                link: `${url}`, // Ensure the URL is correctly formatted
                question: faq?.question || "Unknown question",
              });
            }
          }
        }

        setInnerLinkClickData(transformedData);
        localStorage.setItem(
          "innerLinkClickData",
          JSON.stringify(transformedData)
        );
        console.log("Transformed Inner Link Click Data:", transformedData);
      } catch (error) {
        console.error("Failed to load inner link click data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (faqClickData.length > 0) {
      fetchInnerLinkClickData();
    }
  }, [publicId, faqClickData]);

  useEffect(() => {
    isInitialMount.current = false;
  }, []);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleAccordion = (visitorId) => {
    setExpandedId(expandedId === visitorId ? null : visitorId);
  };

  const encodeHref = (href) => {
    return href.replace(/\./g, "__DOT__");
  };

  const decodeHref = (href) => {
    return href.replace(/__DOT__/g, ".");
  };

  // Function to handle analyze button click
  const handleAnalyzeClick = async () => {
    const allData = JSON.parse(localStorage.getItem("allData"));
    const prompt = `Analyze this data and make product improvement or marketing recommendations for the product. Data: ${JSON.stringify(
      allData
    )}`;

    const requestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: prompt }],
      max_tokens: 1500,
    });

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: requestBody,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.choices && data.choices.length > 0) {
        setAnalysisResult(data.choices[0].message.content);
      } else {
        throw new Error("No analysis returned from GPT-3");
      }
    } catch (error) {
      console.error("Failed to analyze data:", error);
      setError("Failed to analyze data");
    }
  };
  return (
    <div className="pageMetaData">
      {localStorage.signedIn && (
        <div className="subheader faq-buttons">
          <h2 className="page-title">Analytics</h2>
          <button className="button-md" onClick={handleAnalyzeClick}>
            <i data-feather="database"></i> <span>Analyze Data</span>
          </button>
        </div>
      )}
      {analysisResult && (
        <div className="analysis-result">
          <h3>Analysis Result:</h3>
          <p>{analysisResult}</p>
        </div>
      )}
      <div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            {error && <p>{error}</p>}
            <div className="analytics-container">
              <div className="analytics-background-box">
                <div className="analytics-text-header">
                  Total Unique Visitors
                </div>
                <div className="analytics-text-value">
                  {uniqueVisitors || "No data available"}
                </div>
              </div>
              <div className="analytics-background-box">
                <div className="analytics-text-header">Total Views</div>
                <div className="analytics-text-value">
                  {totalVisits || "No data available"}
                </div>
              </div>
            </div>
            <div className="mostViewedFAQs-container">
              <label>The most clicked FAQs</label>
              <table className="fixed-width-table">
                <thead>
                  <tr>
                    <th className="fixed-width-clicks">Clicks</th>
                    <th className="fixed-width">Question</th>
                  </tr>
                </thead>
                <tbody>
                  {faqClickData.length > 0 ? (
                    faqClickData.map((faq) => (
                      <tr key={faq.id}>
                        <td className="fixed-width-clicks">{faq.clicks}</td>
                        <td className="fixed-width">{faq.question}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mostViewedFAQs-container">
              <label>The most clicked categories</label>
              <table className="fixed-width-table">
                <thead>
                  <tr>
                    <th className="fixed-width-clicks">Clicks</th>
                    <th className="fixed-width">Category</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryClickData.length > 0 ? (
                    categoryClickData.map((category) => (
                      <tr key={category.name}>
                        <td className="fixed-width-clicks">
                          {category.clicks}
                        </td>
                        <td className="fixed-width">{category.name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mostViewedFAQs-container">
              <label>The most clicked inner links</label>
              <table className="fixed-width-table">
                <thead>
                  <tr>
                    <th className="fixed-width-clicks">Clicks</th>
                    <th className="fixed-width-link">Link</th>
                    <th className="fixed-width-question">Question</th>
                  </tr>
                </thead>
                <tbody>
                  {innerLinkClickData.length > 0 ? (
                    innerLinkClickData.map((item, index) => (
                      <tr key={index}>
                        <td className="fixed-width-clicks">{item.clicks}</td>
                        <td className="fixed-width-link">
                          <a
                            href={
                              item.link.startsWith("mailto:")
                                ? item.link
                                : `https://${decodeHref(item.link)}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {decodeHref(item.link)}
                          </a>
                        </td>
                        <td className="fixed-width-question">
                          {item.question}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mostViewedFAQs-container">
              <label>Chat Histories</label>
              {chatHistories.length > 0 ? (
                chatHistories.map((history) => (
                  <div key={history._id} className="chatHistory">
                    <div
                      className="visitorId"
                      onClick={() => toggleAccordion(history._id)}
                    >
                      <span className="visitorText">
                        Visitor ID: {history._id}
                      </span>
                      <span className="chevron">
                        {expandedId === history._id ? "-" : "+"}
                      </span>
                    </div>
                    {expandedId === history._id && (
                      <div className="messages">
                        {history.messages.map((msg, idx) => (
                          <div key={idx} className={`message ${msg.role}`}>
                            <span>{msg.content}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div>No data available</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Analytics;
