import React, { useState, useEffect } from "react";
import ContentCard from "./ContentCard";
import ContentCardModal from "./ContentCardModal";
import axios from "axios";
import "./ContentCard.css";

const ListContentCards = ({ viewMode }) => {
  const [cards, setCards] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("edit");
  const [editingCardIndex, setEditingCardIndex] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Use this to manage your loading state
  const [isGenerateFAQDisabled, setIsGenerateFAQDisabled] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true); // State to manage initial loading
  const [faqs, setFaqs] = useState([]); // State to hold FAQ data

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Start loading
        const publicId = localStorage.getItem("publicId");

        const [contentCardsResponse, faqsResponse] = await Promise.all([
          axios.get(`/api/content-cards?publicId=${publicId}`),
          axios.get(`/api/faqs?publicId=${publicId}`),
        ]);

        const contentCards = contentCardsResponse.data.contentCards || [];
        setCards(contentCards);
        localStorage.setItem("contentCards", JSON.stringify(contentCards));

        const fetchedFaqs = faqsResponse.data || [];
        setFaqs(fetchedFaqs);
        localStorage.setItem("faqs", JSON.stringify(fetchedFaqs));
        console.log("Detecting isFAQ from Content Cards:", localStorage.faqs);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An unexpected error occurred. Please try again later.");
      } finally {
        setIsLoading(false); // End loading
        setIsInitialLoading(false); // Set initial loading to false after data fetch
      }
    };

    fetchData();
  }, [localStorage.getItem("publicId")]); // Add localStorage.getItem("userEmail") to the dependency array

  const handleAddCard = () => {
    setEditingCardIndex(null);
    setModalType("edit");
    setIsModalOpen(true);
  };

  const handleEdit = (id) => {
    setEditingCardIndex(id);
    setModalType("edit");
    setIsModalOpen(true);
  };

  const handleDeleteConfirmation = (id) => {
    setEditingCardIndex(id);
    setModalType("delete");
    setIsModalOpen(true);
  };

  const handleSaveCard = async (inputData) => {
    try {
      const email = localStorage.getItem("userEmail");
      let response;

      // Convert id to a number if it exists, otherwise leave it undefined
      const cardId = inputData.id;

      if (cardId) {
        // If inputData contains an id, it's an update operation

        response = await axios.put(`/api/content-cards/${cardId}`, {
          email,
          contentCardData: { ...inputData, id: cardId },
        });
      } else {
        // If inputData does not contain an id, it's a creation operation

        response = await axios.post("/api/content-cards", {
          email,
          contentCardData: inputData,
        });
      }

      if (response.data.contentCard) {
        const newCard = response.data.contentCard;
        setCards((prevCards) =>
          cardId
            ? prevCards.map((card) =>
                card.id === cardId ? { ...card, ...inputData } : card
              )
            : [...prevCards, newCard]
        );
        setIsModalOpen(false);
      } else {
        throw new Error("Failed to save the card"); // Fallback error handling
      }
    } catch (error) {
      console.error("Error saving content card:", error);
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  const confirmDelete = async () => {
    try {
      const email = localStorage.getItem("userEmail");
      const response = await axios.delete(
        `/api/content-cards/${editingCardIndex}?email=${encodeURIComponent(
          email
        )}`
      );

      if (response.data.message === "Content card deleted successfully") {
        const updatedCards = cards.filter(
          (card) => card.id !== editingCardIndex
        );
        setCards(updatedCards);
        setIsModalOpen(false);
      } else {
        throw new Error("Error deleting content card");
      }
    } catch (error) {
      console.error("Error deleting content card:", error);
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  ///////////////////////
  //Generate FAQ FLOW //

  const handleGenerateFAQ = async () => {
    setIsLoading(true); // Start loading
    setIsGenerateFAQDisabled(true);

    const email = localStorage.getItem("userEmail");
    if (!email) {
      alert("User email is not available. Please log in again.");
      setIsLoading(false); // Stop loading if no email
      setIsGenerateFAQDisabled(false);
      return;
    }

    try {
      const contentCardsResponse = await axios.get(
        `/api/content-cards/${encodeURIComponent(email)}`
      );

      if (
        contentCardsResponse.status === 200 &&
        contentCardsResponse.data.contentCards
      ) {
        setTimeout(() => {
          setIsLoading(false); // Stop loading after processing
        }, 2000); // Simulate a delay
        const contentCardMessages = contentCardsResponse.data.contentCards.map(
          (card) => ({
            content: `Title: ${card.title}, Description: ${card.description}`,
            role: "system",
          })
        );

        const faqContextMessage = {
          content:
            "You are a FAQ builder. Based on our conversation and the content provided so far, come up with FAQs for the users and audience for the service and product we have discussed. Generate every small possible FAQs as possible that you can think of. Come up with questions from all types of different customers and the angles, from sales, business, and students. Also how will the beginner vs. the later users will look for different questions. Come up with FAQs that this type of business in the industry needs to have answer for. If the answers for those questions are not provided, just provide with the questions with null answer. Please return this in JSON Format without a title, just an array of question, and answer fields. Please do not include any unnecessary characters to keep it a simple JSON safe format, No ID After coming up with the list, re look at the list just one more time and come up with more FAQ eustions. We need to build robust FAQs, so we can not miss anything. In terms of the answer, your job is to provide the answer as quick as possible, so be concise and get to the answer. After you anwer the question, then be through with the explanation of the answer. After the knowledge has been shared, use provide CTA or links to inrease the CTR of this FAQ. The KPI will be CTR (it could be buy, sign up, contact, .etc)",
          role: "system",
        };

        const updatedMessages = [...contentCardMessages, faqContextMessage];

        const requestBody = JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: updatedMessages,
          max_tokens: 4000,
        });

        const gptResponse = await fetch(
          "https://api.openai.com/v1/chat/completions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            },
            body: requestBody,
          }
        );

        if (!gptResponse.ok) {
          const errorDetails = await gptResponse.text(); // getting text of the error response
          console.error("GPT-3 API error response:", errorDetails);
          throw new Error(`HTTP error! status: ${gptResponse.status}`);
        }

        const data = await gptResponse.json();

        const finalData = data.choices[0].message.content;

        const userEmail = localStorage.getItem("userEmail");
        axios
          .post("/api/generate-faqs", {
            email: userEmail,
            faqList: finalData,
          })
          .then((response) => {
            window.location.reload();
            setIsLoading(false); // Reset loading state
            setIsGenerateFAQDisabled(false);
          })
          .catch((error) => {
            console.error("Failed to generate and save FAQs:", error);
            setIsLoading(false); // Reset loading state on error
          });

        if (data.choices && data.choices.length > 0) {
          const message = data.choices[0].message;
        } else {
        }

        // Handle data from GPT-3 here
      } else {
        throw new Error("Failed to fetch content cards or no cards available");
      }
    } catch (error) {
      console.error("Error during FAQ generation:", error);
      setIsLoading(false);
      setIsGenerateFAQDisabled(false);
    }
  };

  return (
    <div className="knowledgeContainer">
      {viewMode !== "viewerPage" && (
        <div className="subheader faq-buttons">
          <h2 className="page-title"> Knowledge Base </h2>
          <div className="subheader-button-group">
            <button className="button-md" onClick={handleAddCard}>
              <i data-feather="plus-circle"></i> Add Knowledge
            </button>
            <button
              onClick={handleGenerateFAQ}
              className={`button-md2 ${
                isLoading || cards.length === 0 || isGenerateFAQDisabled
                  ? "disabled"
                  : ""
              }`}
              disabled={
                isLoading || cards.length === 0 || isGenerateFAQDisabled
              }
            >
              <i data-feather="coffee"></i>
              {isLoading ? "Generating..." : "Generate FAQ"}
            </button>
          </div>
        </div>
      )}
      <div>
        {!isInitialLoading && cards.length === 0 ? (
          <div className="empty-state-container">
            <div>
              <img
                src="https://i.imgur.com/0FwCxty.png"
                className="empty-image"
              />
            </div>
            <div className="empty-state">
              <h2>Welcome! Let's Get Started.</h2>
              <p>
                To generate your FAQs, we need some information about your
                company or service. Click the "Add Knowledge" button to begin.
              </p>
              <button className="button-md" onClick={handleAddCard}>
                <i data-feather="plus-circle"></i> Add Knowledge
              </button>
            </div>
          </div>
        ) : (
          <>
            {cards.length > 0 && faqs.length === 0 && (
              <div className="empty-state-container">
                <div>
                  <img
                    src="https://i.imgur.com/WIo1yWY.png"
                    className="empty-image"
                  />
                </div>
                <div className="empty-state">
                  <h2>Knowledge Added, but No FAQs Generated</h2>
                  <p>
                    You've added valuable knowledge. Click "Generate FAQ" to
                    transform your knowledge into helpful FAQs.
                  </p>

                  <button
                    onClick={handleGenerateFAQ}
                    className={`button-md2 ${
                      isLoading || isGenerateFAQDisabled ? "disabled" : ""
                    }`}
                    disabled={isLoading || isGenerateFAQDisabled}
                  >
                    <i data-feather="coffee"></i>
                    {isLoading ? "Generating..." : "Generate FAQ"}
                  </button>
                </div>
              </div>
            )}
            <div className="sectionHeader">
              <div>
                <h3>Documents</h3>
              </div>
              <div className="header-content-bottom">
                <p>
                  Add informative documents to build a robust FAQ. PRD, UX,
                  marketing docs.
                </p>
              </div>
            </div>
            {cards.map((card) => (
              <ContentCard
                key={card.id}
                cardData={card}
                onEdit={() => handleEdit(card.id)}
                onDelete={() => handleDeleteConfirmation(card.id)}
              />
            ))}
          </>
        )}
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          {modalType === "message" ? (
            <div className="modal-message">
              <button
                className="closeButton"
                onClick={() => setIsModalOpen(false)}
              >
                ×
              </button>
              <h2>Content Cards Found, but No FAQs Generated</h2>
              <p>
                You have added content cards but no FAQs have been generated
                yet. Click the "Generate FAQ" button to create FAQs from your
                content cards.
              </p>
              <div className="modal-button-group">
                <button
                  onClick={handleGenerateFAQ}
                  className={`button-md2 ${
                    isLoading || isGenerateFAQDisabled ? "disabled" : ""
                  }`}
                  disabled={isLoading || isGenerateFAQDisabled}
                >
                  <i data-feather="coffee"></i>
                  {isLoading ? "Generating..." : "Generate FAQ"}
                </button>
                <button
                  className="button-md"
                  onClick={() => setIsModalOpen(false)}
                >
                  I will do it later
                </button>
              </div>
            </div>
          ) : (
            <ContentCardModal
              onClose={() => setIsModalOpen(false)}
              onSave={modalType === "delete" ? confirmDelete : handleSaveCard}
              existingData={
                editingCardIndex !== null
                  ? cards.find((card) => card.id === editingCardIndex)
                  : {}
              }
              modalType={modalType}
            />
          )}
        </div>
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default ListContentCards;
