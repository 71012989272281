import FAQPage from "./FAQPage";
import { useEffect } from "react";
import axios from "axios";

const FAQContainer = ({
  faqData,
  viewMode,
  categoryNumber,
  isEmbed,
  isSignedIn,
  setHasFaqs,
}) => {
  console.log("FAQContainer Signin:", isSignedIn);
  // Accept viewMode prop
  // Function to parse the query parameters from the URL
  const getQueryParam = (name) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name);
  };

  // Get the email from the query parameters
  const email = getQueryParam("email");

  // Fetch FAQs and update setHasFaqs
  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const publicId = localStorage.getItem("publicId");
        const response = await axios.get("/api/faqs", {
          params: { publicId },
        });
        setHasFaqs(response.data.length > 0);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
        setHasFaqs(false); // In case of error, assume no FAQs
      }
    };

    fetchFAQs();
  }, [setHasFaqs]);

  return (
    <FAQPage
      pageOwner={email || "Your Company Name"}
      isSignedIn={isSignedIn}
      faqData={faqData}
      viewMode={viewMode} // Pass viewMode down to FAQPage
      categoryNumber={categoryNumber}
      isEmbed={isEmbed}
      setHasFaqs={setHasFaqs} // Pass setHasFaqs to FAQPage
    />
  );
};

export default FAQContainer;
