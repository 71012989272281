import React from "react";
import "./IframesModal.css"; // Make sure this import is correct

const IframesModal = ({ isOpen, onClose }) => {
  const publicId = localStorage.getItem("publicId");

  // Construct the iframe src URL using the publicId
  const iframeSrc = `https://www.faqio.co/demo/${publicId}`;

  const iframeCode = `<iframe width="560" height="315" src="${iframeSrc}" title="FAQs" frameborder="0" allowfullscreen></iframe>`;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(iframeCode);
      alert("Code copied successfully!");
    } catch (err) {
      alert(`Failed to copy text: ${err}`);
    }
  };

  if (!isOpen || !publicId) return null; // Also ensure modal does not open if publicId is not available

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="modal-content">
        <h2 className="modal-h2">Iframe Code</h2>
        <textarea readOnly className="modal-textarea" value={iframeCode} />
        <div className="modal-buttons">
          <button onClick={handleCopy}>Copy Code</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default IframesModal;
