import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PageMetaData.css";

const FontSelector = ({ selectedFont, onChange }) => {
  const [fonts, setFonts] = useState([]);

  useEffect(() => {
    const fetchFonts = async () => {
      try {
        const response = await axios.get(
          "https://www.googleapis.com/webfonts/v1/webfonts",
          {
            params: {
              key: process.env.REACT_APP_GOOGLE_FONTS_API_KEY,
              sort: "alpha",
            },
          }
        );
        setFonts(response.data.items);
      } catch (error) {
        console.error("Error fetching fonts:", error);
      }
    };

    fetchFonts();
  }, []);

  return (
    <div className="pageSettings">
      <label>Select Font</label>
      <select value={selectedFont} onChange={(e) => onChange(e.target.value)}>
        {fonts.length === 0 ? (
          <option>Loading fonts...</option>
        ) : (
          fonts.map((font) => (
            <option key={font.family} value={font.family}>
              {font.family}
            </option>
          ))
        )}
      </select>
    </div>
  );
};

export default FontSelector;
