import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import IframesModal from "./IframesModal"; // Adjust the path as necessary
import "feather-icons";
import feather from "feather-icons";
import "./Header.css";

const Header = ({
  signedIn,
  hasFaqs, // Add this prop
  onSignOut,
  onViewLiveClick,
  onSignInClick,
  onSignUpClick,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for the hamburger menu
  const menuRef = useRef(null); // Reference to the menu

  const handleEmbedClick = () => {
    setModalOpen(true); // Open the modal when Embed is clicked
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  const toggleMenu = (event) => {
    event.stopPropagation(); // Prevent the click event from propagating
    setIsMenuOpen(!isMenuOpen); // Toggle the hamburger menu
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    feather.replace();
  });

  return (
    <>
      <div
        className={`header ${
          signedIn ? "header-sign-out visible" : "header-sign-in"
        }`}
      >
        <div className="leftSection">
          <div
            className={`menuIconContainer ${isMenuOpen ? "active" : ""}`}
            onClick={toggleMenu}
          >
            <i data-feather="menu" className="menuIcon"></i>
          </div>
          {!signedIn && (
            <>
              <span className="HeaderLogo">
                <img src="https://i.imgur.com/m0pyN61.png" alt="FAQIO Logo" />
              </span>
              <span className="HeaderLogoText">FAQIO</span>
            </>
          )}
        </div>
        <div className="rightLinks">
          {signedIn ? (
            <>
              {localStorage.getItem("faqs") &&
                JSON.parse(localStorage.getItem("faqs")).length > 0 && (
                  <>
                    <span onClick={handleEmbedClick}>
                      <i data-feather="code"></i> Embed
                    </span>
                    <span
                      onClick={() =>
                        window.open(
                          `/view/${localStorage.getItem("publicId")}`,
                          "_blank"
                        )
                      }
                    >
                      <i data-feather="monitor"></i> View Live
                    </span>
                  </>
                )}
            </>
          ) : (
            <>
              <a
                href="https://calendly.com/djteknokid/usexpansion30min"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i data-feather="calendar"></i> Request a Demo
              </a>
              <a href="#" onClick={onSignInClick}>
                <i data-feather="log-in"></i> Sign in
              </a>
              <button className="start-button-header" onClick={onSignUpClick}>
                <i data-feather="zap"></i>
                <span>Create FAQ</span>
              </button>
            </>
          )}
        </div>
      </div>
      <div className={`menuOverlay ${isMenuOpen ? "open" : ""}`} ref={menuRef}>
        <div className="menuContent">
          <ul>
            {signedIn ? (
              <>
                <li>
                  <Link to="/faq" onClick={toggleMenu}>
                    <i data-feather="message-circle"></i> My FAQs
                  </Link>
                </li>
                <li>
                  <Link to="/knowledge" onClick={toggleMenu}>
                    <i data-feather="package"></i> Knowledge Base
                  </Link>
                </li>
                <li>
                  <Link to="/analytics" onClick={toggleMenu}>
                    <i data-feather="bar-chart-2"></i> Analytics
                  </Link>
                </li>
                <li>
                  <Link to="/settings" onClick={toggleMenu}>
                    <i data-feather="settings"></i> Public Page Settings
                  </Link>
                </li>

                <li>
                  <span onClick={onSignOut}>
                    <i data-feather="log-out"></i> Sign Out
                  </span>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a href="mailto:hifaqio@gmail.com">
                    <i data-feather="mail"></i> Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/104182113/admin/feed/posts/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i data-feather="info"></i> About us
                  </a>
                </li>
                <li>
                  <a
                    href="/view/Pf9yEFYd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i data-feather="message-circle"></i> FAQ
                  </a>
                </li>
                <li>
                  <a href="#" onClick={onSignUpClick}>
                    <i data-feather="zap"></i> Create Your FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="https://calendly.com/djteknokid/usexpansion30min"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i data-feather="calendar"></i> Request a Demo
                  </a>
                </li>
                <li>
                  <a href="#" onClick={onSignInClick}>
                    <i data-feather="log-in"></i> Sign in
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <IframesModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Header;
