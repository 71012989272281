import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./ChatBot.css"; // Import CSS for styling
import { useParams } from "react-router-dom";

const ChatBot = ({
  chatHistory = [],
  contentCards,
  onChatGPTData,
  viewMode,
  resetMode,
  faqData, // Add faqData prop
}) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isGeneratingFAQ, setIsGeneratingFAQ] = useState(false); // State for loading
  const chatMessagesRef = useRef(null);
  const inputGroupRef = useRef(null);
  const { publicId: publicIdFromUrl } = useParams();
  const [visitorId, setVisitorId] = useState("");

  //generating Visitor ID for logging purpose //
  useEffect(() => {
    let existingVisitorId = localStorage.getItem("visitorId");
    if (!existingVisitorId) {
      // Generate a new visitor ID
      existingVisitorId = `visitor-${Math.random().toString(36).substr(2, 9)}`;
      localStorage.setItem("visitorId", existingVisitorId);
    }
    setVisitorId(existingVisitorId);
  }, []);

  useEffect(() => {
    if (resetMode) {
      // Check if resetMode is true
      // Clear chat history when resetMode is true
      setMessages([]);
      localStorage.removeItem("chatMessages");
    } else {
      // Normal behavior
      if (viewMode === "viewerPage") {
        setMessages([{ content: "who are you?", role: "user" }]);

        sendMessageToChatGPT(
          "You are a customer service chatbot. If this is the firs time talking, greet with How may I help you awesome customer? If the prompt user sends deemed to be a legitimate FAQ / support related promp, response back with MONGODB SEARCH so we can make a mongoDB search. If it's not a legimiate support needs prompt, just answer back as is",
          false
        );
      } else {
        const publicId = publicIdFromUrl;
        fetchChatHistory(publicId);
      }
    }
  }, [viewMode, resetMode]); // Listen to changes in viewMode and resetMode

  // Function to fetch chat history
  const fetchChatHistory = async (email) => {
    try {
      const response = await axios.get(
        `/api/chat-history/${encodeURIComponent(email)}`
      );
      const chatHistory = response.data; // Assuming the response contains chat history
      setMessages(chatHistory);
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  // Function to send message to ChatGPT
  const sendMessageToChatGPT = async (
    currentMessages,
    isGeneratingFAQ,
    userMessage
  ) => {
    // Map each message in currentMessages to create messagesPayload
    const messagesPayload = currentMessages.map((msg) => ({
      role: msg.role === "user" ? "user" : "assistant",
      content: msg.content,
    }));

    let requestBody; // Define requestBody here
    if (viewMode === "viewerPage") {
      const content = messagesPayload.map((msg) => msg.content).join(" ");

      // Transform contentCards into an array of text messages
      const contentCardMessages = contentCards.map((card) => ({
        role: "system",
        content: `Title: ${card.title}, Description: ${card.description}`,
      }));

      // Transform faqData into an array of text messages
      const faqMessages = faqData.map((faq) => ({
        role: "system",
        content: `FAQ ID: ${faq.id}, Question: ${faq.question}, Answer: ${faq.answer}`,
      }));

      const viewModeInitMsg = [
        {
          role: "system",
          content:
            "You are a customer service representative directly representing our company. Based on the ongoing conversation and any presented information, you're tasked to provide clear and helpful answers. If a user's question is clearly marked with [Attention], please focus on providing a thorough answer. If you're unsure about the answer or if the question lacks details, don't resort to guessing. Instead, politely ask for more information to clarify the user's need. Always maintain a helpful demeanor, avoid stating simply ,I don't know, and try to guide users towards understanding even if the exact answer isn't available. Remember, you embody the company's voice; always engage respectfully and informatively, never distancing yourself as an external entity. Look at the information one more time before answer any question to make sure you have understood everything properly and correctly. Make the response concise, simple. Pick out the keywords from the user's prompt and see how we can provide value to solve this issue. If the solution is not given, ask for email to set up a meeting. Do not say things like 'Based on the information provided,' You are the brand and you have the information. If more information from the user is necessary, please ask. Example, why is having FAQ important?, this could have different answer for different companies in different size and state. Also the motivation could be different with FAQ. We could ask for the size and etc.",
        },
      ];

      // Flatten the array to combine all messages
      const updatedMessages = [
        ...viewModeInitMsg,
        ...currentMessages,
        ...contentCardMessages,
        ...faqMessages,
      ];

      requestBody = JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: updatedMessages,
        max_tokens: 4000,
      });
    } else {
      requestBody = JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: messagesPayload,
        max_tokens: 4000,
      });
    }

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`, // Ensure this is correct
          },
          body: requestBody,
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const botResponse = {
        visitorId,
        role: "assistant",
        content: data.choices[0].message.content,
      };

      // Handling Generate FAQ Flow
      if (isGeneratingFAQ) {
        const finalData = data.choices[0].message.content;
        const userEmail = localStorage.getItem("userEmail");

        axios
          .post("/api/generate-faqs", {
            email: userEmail,
            faqList: finalData, // Stringify the JSON data
          })
          .then((response) => {
            setIsGeneratingFAQ(false); // Reset loading state
          })
          .catch((error) => {
            console.error("Failed to generate and save FAQs:", error);
            setIsGeneratingFAQ(false); // Reset loading state on error
          });

        // Extract FAQ data from the JSON response
        const faqData = {
          id: [],
          question: [],
          answer: [],
        };
        window.location.reload();
        return faqData;
      }

      setMessages((prev) => {
        const exists = prev.some(
          (msg) =>
            msg.content === botResponse.content && msg.role === botResponse.role
        );
        return exists ? prev : [...prev, botResponse];
      });
      onChatGPTData(data.choices[0].message.content);

      // Function to remove "[Attention]" tag from messages
      const removeAttentionTag = (messages) => {
        return messages.map((msg) => ({
          ...msg,
          content: msg.content.replace("[Attention] ", ""),
        }));
      };

      // Save the new response from chatGPT to MongoDB
      const publicId = publicIdFromUrl;
      const cleanedMessages = removeAttentionTag([
        ...currentMessages,
        botResponse,
      ]);

      const savingMessages = [userMessage, botResponse];

      await axios.post("/api/save-chat", {
        publicId: publicId,
        messages: savingMessages,
      });

      if (viewMode === "viewerPage") {
        sessionStorage.setItem(
          "chatMessages",
          JSON.stringify([...messages, botResponse])
        );
      } else {
        localStorage.setItem(
          "chatMessages",
          JSON.stringify([...messages, botResponse])
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setMessages((prev) => [
        ...prev,
        { content: `Error: ${error.message}`, role: "assistant" },
      ]);
      setIsGeneratingFAQ(false); // Reset loading state on error
    }
  };

  useEffect(() => {
    const storedMessages = localStorage.getItem("chatMessages");
    setMessages(storedMessages ? JSON.parse(storedMessages) : []);
  }, []);

  useEffect(() => {
    if (chatMessagesRef.current && inputGroupRef.current) {
      const chatHeight = chatMessagesRef.current.clientHeight;
      if (chatHeight > 500) {
        inputGroupRef.current.style.position = "sticky";
        inputGroupRef.current.style.bottom = "0";
      } else {
        inputGroupRef.current.style.position = "static";
      }
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (!inputText.trim()) return;

    const userMessage = {
      visitorId,
      role: "user",
      content: inputText, // Original message content without [Attention]
    };

    setMessages((prev) => [...prev, userMessage]);

    // Append [Attention] to userMessage.content before sending to ChatGPT
    const modifiedUserMessage = {
      ...userMessage,
      content: "[Attention] " + userMessage.content,
    };

    setInputText("");

    sendMessageToChatGPT(
      [...messages, modifiedUserMessage],
      false,
      userMessage
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div>
      <div>
        <div className="sectionHeader">
          <div ref={chatMessagesRef} className="messages-container">
            <div className="chat-messages">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`message ${
                    msg.role === "user" ? "user" : "assistant"
                  }`}
                >
                  {msg.content}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div ref={inputGroupRef} className="input-group">
        <div className="input-area">
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Your message"
            className="input-field"
          />
          <button onClick={handleSendMessage} className="send-button button-md">
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
